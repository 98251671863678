<div class="full-page"><h4>Approved Leave</h4>
<mat-card style="margin-top: 20px;">
  <mat-card-content>
    <div class="bootstrap-wrapper">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <form (ngSubmit)="formSubmit($event)">
           <div class="task-info">
              <label for="Task Id" style="font-weight: 500;">Leave Id</label>
              <button mat-mini-fab type="button" id="id" name="id" [value]="leaveId" disabled style="margin-left:20px;color: white;background-color: #ff4081;">{{ leaveId }}</button>
            </div>
            <mat-form-field class="w100" appearance="outline" style="margin-top: 34px">
              <mat-label>Approval Status</mat-label>
              <mat-select [(ngModel)]="selectedStatus" name="managerStatus" id="status" style="width: 100%;" required>
                <mat-option *ngFor="let status of statuses" [value]="status.value">{{ status.viewValue }}</mat-option>
              </mat-select>
              
            </mat-form-field>
            <mat-form-field class="w100"  appearance="outline">
              <mat-label>Add Comment</mat-label>
              <textarea  required autofocus   type="text" id="comment"  matInput placeholder="Enter here"  name="task" rows="10"  ></textarea>
            </mat-form-field>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
</div>