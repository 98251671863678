<div class="employee-details">
  <div class="list-data" style="display: flex; justify-content: space-between;">
    <h2>Employee Task</h2>
    <h4>{{ user.department }}</h4>
  </div>

  <!-- Display Total Tasks -->
  <div style="margin-bottom: 10px;">
    <h4>Total Tasks: {{ data?.['Total Task'] || 0 }}</h4>
  </div>

  <!-- Pie Chart Display -->
  <div style="margin-bottom: 20px;">
    <ngx-charts-pie-grid
      *ngIf="pieChartData.length > 0"
      [results]="pieChartData"
      [scheme]="colorScheme"
      [legend]="true"
      [labels]="true"
      [view]="chartDimensions"> 
    </ngx-charts-pie-grid>
  </div>
</div>
