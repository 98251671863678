<div class="full-page"><h4>Attendance</h4>
<div class="header-row">
  <h5>Attendance List</h5>
  <div class="button-group">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
    <button mat-button class="custom-button" (click)="navigateToAddUserAttendance()">
      <mat-icon>fingerprint</mat-icon> Mark Your Presence
    </button>
    <button mat-button class="custom-button" [matMenuTriggerFor]="mainMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>
<div class="filter-container">
  <mat-menu #mainMenu="matMenu">
    <button mat-menu-item (click)="filterByWeek()">
      <mat-icon>calendar_view_week</mat-icon>
      Week
    </button>
    <button mat-menu-item (click)="filterByMonth()">
      <mat-icon>calendar_view_month</mat-icon>
      Month
    </button>
    <button mat-menu-item (click)="filterByQuarter()">
      <mat-icon>calendar_view_day</mat-icon>
      Quarter
    </button>
  </mat-menu>

  <div class="filter-form-wrapper" *ngIf="showFilterForm" >
    <div class="filter-btn">
      <h2>Filter</h2>
      <button mat-icon-button (click)="toggleFilterForm()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <form #filterForm="ngForm" (submit)="attendanceFilter($event)">
      <div class="form-group">
        <label for="dateRange" >Select Date:</label><br>
        <div style="display: flex;">
          <input
            id="startDate"
            name="startDate"
            [(ngModel)]="startDate"
            type="date"
            class="form-control"
            style="width: 100%; margin-right: 10px;"
          />
          <input
            id="endDate"
            name="endDate"
            [(ngModel)]="endDate"
            type="date"
            class="form-control"
            style="width: 100%;"
          />
        </div>
      </div>
      <div class="form-group" >
        <label>Select Employee:</label>
        <select [(ngModel)]="selectedEmployee" name="employee" id="employee" class="selector" required >
          <option value="">Select Employee</option> 
          <option *ngFor="let employee of user" [value]="employee.id" class="custom-option">
            {{employee.userName}}
          </option>
        </select>
        
      </div>
      <div class="filter-btn">
        <button mat-button color="warn" (click)="clearFilters()">Clear</button>
        <button mat-raised-button color="accent" type="submit" class="selector">Filter</button>
      </div>
    </form>
  </div>
</div>

<div class="content-wrapper" >
  <section class="Leads_manage leads_redesign">
    <div class="container2">
      <div class=" scrollable-content">
        <div class="row">
          <div class="col-md-12">
            <div class="in_time_location">
              <div class="table-responsive table-fixed table-bg">
                <table class="table-hover table-striped text-center">
                  <thead>
                    <tr>
                      <th rowspan="2" >Sn.</th>
                      <th rowspan="2"  *ngIf="!isClickedOnAttendance">
                        Name.
                      </th>
                      <th rowspan="2" >Date</th>
                    </tr>
                    <tr>
                      <th >In Time</th>
                      <th >Out Time</th>
                    </tr>
                  </thead>
                  <tbody class="serialNumber">
                    <tr *ngFor="let user of userAttendanceList">
                      <td class="td_center">{{ user.serialNumber }}</td>
                      <td class="td_center" *ngIf="!isClickedOnAttendance">
                        {{ user.userName }}
                        <br /><br /><br />
                        <a (click)="liveLocation(user.userId, user.userName)">
                          <img src="../../../../assets/loaction-icon.webp" alt="location Icon" style="width: 64px; margin-top: -26px; height: 40px;" />
                        </a>
                      </td>
                      <td class="td_center">{{ user.date }}</td>
                      <td class="td_center" *ngIf="user.inTime">
                        {{ user.inTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.inTime"></td>
                      <td class="td_center" *ngIf="user.outTime">
                        {{ user.outTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.outTime"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Attendance
    </div>
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>
</div>