import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css','/../../../shared/all-style/review-performance.component.css']
})
export class ReviewComponent implements OnInit {
  id: number;
  user_id = localStorage.getItem('id');
  month: string;
  data: any = {};
  circleDasharray = 283;
  circleDashoffset: any;
  strokeColor: string;
  performanceMessage: string;
  report: any = {};
  userReviews: number;
  teamWork: number;
  generalConduct: number;
  jobKnowledge: number;
  workQuality: number;
  skills: number;
  assistance: number;
  attendance: number; 
  workDelivery: number;
  performance: number;
  timeline:any;
  constructor(private datePipe: DatePipe, private mainService: MainService) {
    this.id = +localStorage.getItem('id'); 
  }

  ngOnInit(): void {
    if (!this.id) {
      console.error('User ID is null or invalid');
      return; // Handle the absence of user ID appropriately
    }

    this.mainService.performance(this.id, this.month).subscribe((res: any) => {
      this.data = res;
      this.timeline = this.data.timeline;
      this.calculateCircleDashoffset();
      this.setPerformanceMessage();
      console.log('API Response report:', JSON.stringify(this.data)); 
    }, error => {
      console.error('Error fetching performance data:', error);
    });

    this.mainService.report(this.user_id).subscribe((res: any) => {
      this.report = res[0];
      console.log('API Response reportreport:', this.report); 
    }, error => {
      console.error('Error fetching report data:', error);
    });
  }

  // setReportData(): void {
  //   this.userReviews = 0;
  //   this.teamWork = 0;
  //   this.generalConduct = 0;
  //   this.jobKnowledge = 0;
  //   this.workQuality = 0;
  //   this.skills = 0;
  //   this.assistance = 0;
  //   this.attendance = 0;
  //   this.workDelivery = 0;
  //   this.performance = 0;
  //   if (this.report.length > 0) {
  //     this.report.forEach((item: any) => {
  //       this.userReviews += item.percentage ;
  //       this.teamWork  += (item.teamWork / 5) * 100 || 0;
  //       this.generalConduct += (item.generalConduct / 5) * 100 || 0;
  //       this.jobKnowledge += (item.jobKnowledge / 5) * 100 || 0;
  //       this.workQuality += (item.workQuality / 5) * 100 || 0;
  //       this.skills += (item.skills / 5) * 100 || 0;
  //       this.assistance += (item.assistance / 5) * 100 || 0;
  //       this.attendance += (item.attendance / 5) * 100 || 0;
  //       this.workDelivery += (item.workDelivery / 5) * 100 || 0;
  //       this.performance += item.percentage;
  //     });
  //   } else {
  //     // Handle the case when the report array is empty
  //     this.userReviews = 0;
  //     this.teamWork = 0;
  //     this.generalConduct = 0;
  //     this.jobKnowledge = 0;
  //     this.workQuality = 0;
  //     this.skills = 0;
  //     this.assistance = 0;
  //     this.attendance = 0;
  //     this.workDelivery = 0;
  //     this.performance = 0;
  //   }
  // }

  calculateCircleDashoffset(): void {
    const percentage = Math.round(this.data.overAllPerformace);
    this.circleDashoffset = this.circleDasharray - (this.circleDasharray * percentage / 100);
    this.strokeColor = '#4FBAF0';
  }

  setPerformanceMessage(): void {
    if (this.data.overAllPerformace < 50) {
      this.performanceMessage = "Performance needs improvement!";
    } else if (this.data.overAllPerformace < 75) {
      this.performanceMessage = "Good performance, keep it up!";
    } else if (this.data.overAllPerformace < 90) {
      this.performanceMessage = "Great performance!";
    } else {
      this.performanceMessage = "Outstanding performance!";
    }
  }
}
