<mat-dialog-content >
    <div class="modal-overlay" (click)="closeModal()"></div>
    <div class="modal-content" style="width: 34%;">
        <span class="close" (click)="closeModal()">&times;</span>

        <h2>{{ events[0]?.subject }}</h2>
        
        <p>{{ events[0]?.eventDateTime | date:'dd-MMM-yyyy hh:mm:a' }}</p>
        <img *ngIf="events[0]?.poster" [src]="events[0]?.poster" alt="Event Image" style="width: 100%;height:300px">
        <p>{{ events[0]?.description }}</p>
    </div>
</mat-dialog-content>

