<div class="full-page"><h4>Leave Requests</h4>
<button mat-mini-fab [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/admin/show-all-approved-leave"><mat-icon>check_circle</mat-icon> Approved Leaves</button>
  <button mat-menu-item routerLink="/admin/admin-leave-apply">
    <mat-icon >accessibility_new</mat-icon>Apply For Leave
  </button>
</mat-menu>
<div class="container2" style="margin-top: 30px;">
  <div class="scrollable-content">
    <table>
    <thead>
      <tr>
        <th>Id</th>
        <th>Apply Date</th>
        <th>Employee Name</th>
        <th>Reason</th>
        <th>Status</th>
        <th>Dates</th>
        <th>Leave Type</th>
        <th>Manager Status</th>
        <th><mat-icon mat-list-icon>edit</mat-icon>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of filteredData">
        <td>{{ data.id }}</td>
        <td>{{ data.createdDate | date: 'dd-MMM-yyyy hh:mm a' }}</td>
        <td>{{ data.userName }}</td>
        <td ><span (click)="openUserModal(data.reason,data.status,data.userName,data.leaveType)">{{ data.reason }}</span><span><a *ngIf="data.fileUrl" [href]="data.fileUrl" target="_blank"><img src="../../../../assets/task.png"  style="width: 20px;height:20px"/></a></span></td>
        <td style="    text-transform: uppercase;">{{getpendingtype(data.status)}}</td>
          <td>
            <button mat-button [matMenuTriggerFor]="menu">
              <mat-icon>event</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngFor="let date of data.leaveDates; let i = index">
                {{ date }}
              </button>
            </mat-menu>
          </td>
          <td style="background: #ce3262; color: white;opacity: .9;">{{ getLeaveTypeText(data.leaveType) }}</td>
          <td>{{data.managerStatus}}</td>
        <td>
          <button mat-button [matMenuTriggerFor]="actionMenu"><mat-icon mat-list-icon>more_vert</mat-icon></button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item [routerLink]="['/admin/leaveapprove-dialog']" [queryParams]="{ id: data.userId, reason: data.reason, leave_id: data.id, todate: data.leaveDates[1], fromdate: data.leaveDates[0], leaveType: data.leaveType }"> <mat-icon mat-list-icon>update</mat-icon>Approve</button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div> 

</div>

<div class="pagination-container">
  <div class="pagination-info">
    Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Leave Request
  </div>
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>
</div>
