<div class="container">
    <div class="chart-container">
      <h2>Progress Chart</h2>
      <div class="legend">
        <span class="dot"></span>Monthly Report
      </div>
      <div class="chart">
        <canvas #chartCanvas ></canvas>
      </div>
    </div>
    <div class="review-container">
      <h2>Last Review</h2>
      <div class="review">
        <img [src]="profile?.profilePhoto?(profile?.profilePhoto):'Upload Image'"  alt="Profile photo" class="avatar">
        <div class="review-content">
        <p >{{ review?.givenByName?(review?.givenByName):'Anonymous' }}<span style="color: #666666; font-size: 16px; margin-left: 14px;">{{ formatDate(review?.createdOn) }}</span></p>
        <p>{{ review?.comment ?(review?.comment):'No comments provided yet.'}}</p>
        </div>
      </div>
    </div>
  </div>
  