
// let baseUrl = 'http://148.66.133.154:8080';
// let baseUrl = 'https://api.virtualintelligence.co.in'

// let baseUrl = 'http://13.201.134.139:8080:8080'
// let baseUrl = 'http://api.imconsole.in:8080';
//  let  baseUrl = 'http://192.168.29.202:8080';
 let baseUrl = 'https://dev.virtualintelligence.co.in';
// let baseUrl ='http://13.233.32.30:8282';
// let baseUrl = 'https://api.gopropify.in';
export default baseUrl;