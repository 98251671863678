import { Component, OnInit, Inject } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-update-task',
  templateUrl: './update-task.component.html',
  styleUrls: ['./update-task.component.css']
})
export class UpdateTaskComponent implements OnInit {
  userId = localStorage.getItem('id');
  data: any;
  taskId: string;
  subject: any;

  public user = {
    status: '',
    subject: '',
    deadLine: null
  };

  public deadLineDate: string;
  public deadLineTime: string;

  constructor(
    private mainService: MainService,
    private router: Router,
    private dialogRef: MatDialogRef<UpdateTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    this.taskId = this.dialogData.taskId;
    this.user.status=this.dialogData.status;
    this.user.subject = this.dialogData.subject;
    this.user.deadLine = this.dialogData.deadLine;

    if (this.dialogData.deadLine) {
      const deadline = new Date(this.dialogData.deadLine);
      this.deadLineDate = deadline.toISOString().split('T')[0]; 
      this.deadLineTime = deadline.toTimeString().split(' ')[0].slice(0, 5); 
    } else {
      Swal.fire('No deadline provided'); 
    }

    console.log('User Object on Init:', this.user); 
  }

  onformSubmit(event) {
    event.preventDefault(); 
    console.log('Form Submitted with Event:', event);
    const combinedDateTime = new Date(`${this.deadLineDate}T${this.deadLineTime}`);
    const updatedTask = {
      id: this.taskId,
      status: this.user.status || null,
      subject: this.user.subject || null,
      deadLine: combinedDateTime.getTime()    
    };

    console.log('Task Object Being Sent to API:', updatedTask); 

    this.mainService.taskbyid(this.userId, updatedTask).subscribe(
      (res: HttpResponse<any>) => {
        console.log('API Response:', res);
        this.data = res;
        Swal.fire({
          title: 'Successfully updated',
          text: 'Your task has been updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogRef.close();
            this.router.navigate(['user-dashboard/task']);
            window.location.reload();
          }
        });
      },
      (error: HttpErrorResponse) => {
        console.error('API Error Response:', error); // Debug error response
        if (error.error && error.error.message) {
          Swal.fire('Error', ' ' + error.error.message, 'error');
        } else {
          Swal.fire('Error', 'An unexpected error occurred. Please try again later.', 'error');
        }
      }
    );
  }

  closeModal(): void {
    console.log('Modal Closed'); // Debug when modal is closed
    this.dialogRef.close();
    window.location.reload();
  }
}
