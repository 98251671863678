import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-show-chat',
  templateUrl: './show-chat.component.html',
  styleUrls: ['./show-chat.component.css']
})
export class ShowChatComponent implements OnInit {
  pageSize: number = 10;
  pageNumber: number = 0;
  totalElements: number = 0;
  chat: any;
  employees: any;

  data: any;
  userId: string;
  id = localStorage.getItem('id');
  startDate: string = '';
  endDate: string = '';
  inputDateFromString: any;
  inputDateToString: any;
  showFilterForm: boolean = false;

  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    this.fetchMeetings(this.pageNumber, this.pageSize);
    this.mainService.AllEmployeeProfileList().subscribe((res: any) => {
      this.employees = res; 
    });
  }

  fetchMeetings(pageNumber: number, pageSize: number) {
    const start = this.startDate ? this.mainService.toTimestamp(this.startDate) : null;
    const end = this.endDate ? this.mainService.toTimestamp(this.endDate) : null;

    this.mainService.showMessage(this.id, pageNumber, pageSize, start, end).subscribe(
      (res) => {
        this.data = res;
        this.chat = this.data.content;
        this.totalElements = this.data.totalElements;
        console.log('Fetched meetings:', JSON.stringify(this.data));
      },
      (error) => {
        console.error('Error fetching meetings:', error);
      }
    );
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchMeetings(this.pageNumber, this.pageSize);
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  onSubmitFilter() {
    this.inputDateFromString = this.startDate ? this.mainService.toTimestamp(this.startDate) : null;
    this.inputDateToString = this.endDate ? this.mainService.toTimestamp(this.endDate) : null;
    this.pageNumber = 0; // Reset to the first page when filtering
    this.fetchMeetings(this.pageNumber, this.pageSize);
  }

  clearFilters() {
    this.startDate = '';
    this.endDate = '';
    this.userId = '';
    this.pageNumber = 0; // Reset to the first page
    this.fetchMeetings(this.pageNumber, this.pageSize); // Reset filters and fetch meetings again
  }

  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }

  openPopup(image: string): void {
    window.open(image, 'Document Viewer', 'resizable=yes,scrollbars=yes');
  }

  // Uncomment and complete the following methods if needed

  // onDelete(id: string) {
  //   if (confirm('Are you sure you want to delete?')) {
  //     this.mainService.deleteMeeting(id).subscribe(
  //       (res) => {
  //         console.log('Successfully deleted');
  //         this.fetchMeetings(this.pageNumber, this.pageSize);
  //       },
  //       (error) => {
  //         console.error('Error deleting meeting:', error);
  //         Swal.fire('Error', ' ' + error.error.message, 'error');
  //       }
  //     );
  //   }
  // }

  // getStatusColor(status: string): string {
  //   switch (status) {
  //     case 'DONE':
  //       return 'rgb(103, 59, 141)';
  //     case 'SCHEDULED':
  //       return 'rgb(153, 102, 204)';
  //     case 'SUSPENDED':
  //       return 'rgb(77, 77, 179)';
  //     case 'POSTPONED':
  //       return 'rgb(106, 106, 152)';
  //     case 'PENDING':
  //       return 'rgb(128, 128, 128)';
  //     default:
  //       return 'rgb(106, 106, 152)';
  //   }
  // }
}
