<div class="container">
<h4> Users Review</h4>
<div class="form-field" style="background: rgb(49, 48, 48);">
  <label for="userIds">Select Employee:</label>
  <form (submit)="onSubmit()">
    <select id="userIds" name="employeeSelector" [(ngModel)]="user_id" style="background-color: #2d2d2d; color: white;text-transform: capitalize;">
      <option *ngFor="let employee of data" [value]="employee.id" style="text-transform: capitalize;">
        {{ employee.userName }} 
      </option>
    </select>
    <button  mat-raised-button type="submit" color="accent" (click)="onSubmit()">
      Review </button>
  </form>
</div>
</div>

