import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-manager-hring',
  templateUrl: './manager-hring.component.html',
  styleUrls: ['./manager-hring.component.css', '/../../../shared/all-style/all-style.component.css']
})
export class ManagerHringComponent implements OnInit {
  candidates: any[] = [];
  hiring: any[] = [];
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  userId: string | null = localStorage.getItem('id');
  vacancyId: any;
  isActiveVacancy: boolean | null = null;
  hiringAIStatus: string | null = null;
  status: any;
  startDate: any = null;
  endDate: any = null;
  designation: string | null = null;  
  showFilters: boolean = false;
  showFilterForm: boolean = false;
  inputDateToString: any;
  inputDateFromString: any;

  constructor(private mainService: MainService) { }

  ngOnInit(): void {
    this.fetchData();
    this.fetchVacancies();
  }

  fetchVacancies(): void {
    this.mainService.getAllvacancy().subscribe((res: any) => {
      this.candidates = res;
      console.log("Vacancies: ", this.candidates);
    });
  }

  fetchData(): void {
    // Convert date inputs to timestamps
    this.inputDateFromString = this.startDate ? this.mainService.toTimestamp(this.startDate) : null;
    this.inputDateToString = this.endDate ? this.mainService.toTimestamp(this.endDate) : null;

    // Ensure parameters are passed in the correct order
    this.mainService.getHiringsByAssignedMember(
        this.inputDateFromString,  // startDate
        this.inputDateToString,    // endDate
        this.status,                // status
        this.pageNumber,           // page
        this.pageSize,             // size
        this.userId                 // userId
    ).subscribe((res: any) => {
        this.hiring = res.content;
        this.totalElements = res.totalElements;
        console.log("Hiring Data: ", this.hiring);
    });
}


  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  hasUrl(urls: string[] | null | undefined, type: string): boolean {
    return urls ? urls.some(url => this.getType(url) === type) : false;
  }

  getUrl(urls: string[] | null | undefined, type: string): string | null {
    const url = urls?.find(url => this.getType(url) === type);
    return url ? url : null;
  }

  getType(url: string): string {
    if (url.includes('github.com')) {
      return 'github';
    } else if (url.includes('linkedin.com')) {
      return 'linkedin';
    } else if (url.includes('x.com') || url.includes('twitter.com')) {
      return 'portfolio';
    }
    return '';
  }

  toggleFilterForm(): void {
    this.showFilterForm = !this.showFilterForm;
  }

  onSubmitFilter(): void {
    this.pageNumber = 0;  
    this.fetchData();  // Fetch data with the applied filters
  }

  clearFilters(): void {
    this.status = null;
    this.startDate = null;
    this.endDate = null;
    this.hiringAIStatus = null;
    this.designation = null;
    this.userId = localStorage.getItem('id');  // Reset to current user
    this.fetchData();  // Fetch data with cleared filters
  }
}
