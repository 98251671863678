<div class="container">
    <div class="chart-container">
      <h2>Progress Chart</h2>
      <div class="legend">
        <span class="dot"></span>Monthly Report
      </div>
      <div class="chart">
        <canvas #chartCanvas ></canvas>
      </div>
      
    </div>
   
  <div class="review-container">
    <h2>Last Review</h2>
    <div class="review" *ngIf="review">
      <img [src]="profile?.profilePhoto" alt="Profile photo" class="avatar">
      <div class="review-content">
        <p *ngIf="review.givenByName">{{ review?.givenByName }}<span style="color: #666666; font-size: 16px; margin-left: 14px;">{{ formatDate(review?.createdOn?review.createdOn:0) }}</span></p>
        <p *ngIf="review.comment">{{ review?.comment }}</p>
      </div>
    </div>
    <div *ngIf="!review" class="review">
      <p>No review available.</p>
    </div>
  </div>
  