<header class="text-white">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <div class="logo">
        <a routerLink="/admin" class="navbar-brand" aria-label="HR Console Logo">
          <img src="../../../assets/HR CONSOLE HORIZONTAL copy.png" alt="HR Console" style="height: 58px; border-radius: 10px;margin-left: -55px;" />
          <!-- <span *ngIf="isLoggedIn">Hi, {{ user.firstName }} {{ user.lastName }}🖐️</span> -->
        </a>
      </div>
      <nav class="navbar navbar-expand-lg navbar-dark" >
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item" *ngIf="!isLoggedIn"> 
              <a routerLink="/features" class="nav-link">Features</a>
            </li>
            <li class="nav-item" *ngIf="!isLoggedIn">
              <a routerLink="#" class="nav-link">Visit Website</a>
            </li>
            <li class="nav-item" *ngIf="!isLoggedIn" >
              <a routerLink="#" class="nav-link">Support</a>
            </li>
            <li class="nav-item" *ngIf="!isLoggedIn">
              <a [routerLink]="'/login'" class="nav-link">Login</a>
            </li>
            <!-- <a  [routerLink]="'/login'" class="example-icon" aria-label="Login">
              <button class="login-btn" *ngIf="!isLoggedIn"> Login </button>
            </a> -->
            <!-- Display for logged-in users -->
            <!-- <li *ngIf="isLoggedIn" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" id="notificationDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="../../../assets/bell_svgrepo.com.png" alt="Notifications" style="height: 24px;" />
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notificationDropdown">
                <li><a class="dropdown-item" href="#">Notification 1</a></li>
                <li><a class="dropdown-item" href="#">Notification 2</a></li>
                <li><a class="dropdown-item" href="#">View all notifications</a></li>
              </ul>
            </li> -->

            <li *ngIf="isLoggedIn && user" class="nav-item dropdown" style="background: #454547; border: 1.5px solid #454547; border-radius: 41px; padding: -17px; width: 76px; height: 42px;">
              <a class="nav-link dropdown-toggle" href="#" role="button" id="profile" data-bs-toggle="dropdown" aria-expanded="false" style="text-transform: capitalize">
                <img 
                  src="{{ user.profileImage ?? '../../../../../assets/Abhisheksrivastav.jpeg' }}" 
                  alt="Profile" 
                  class="rounded-circle" 
                  style="height: 30px; width: 30px; margin-right: 8px;" 
                />
              </a>
              
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profile">
                <li><a class="dropdown-item" routerLink="admin/admin-profile" *ngIf="userRole === 'ADMIN'"><mat-icon>person</mat-icon> Manage Profile</a></li>
                <li><a class="dropdown-item" routerLink="manager-dashboard/manager-profile" *ngIf="userRole === 'MANAGER'"><mat-icon>person</mat-icon> Manage Profile</a></li>
                <li><a class="dropdown-item" routerLink="user-dashboard/user-profile" *ngIf="userRole === 'USER'"><mat-icon>person</mat-icon> Manage Profile</a></li>
                <li *ngIf="isLoggedIn"><a (click)="logout()" class="dropdown-item"><mat-icon>logout</mat-icon> Sign Off</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
