<div class="full-page">
<h4> User Performance</h4>

<!-- Create a wrapper to hold both left and right columns -->
<div class="performance-container" >

  <!-- Left column with 6 fields -->
  <div class="left-column">
    <div *ngFor="let category of categories.slice(0, 4)" class="category">
      {{ category }}:
      <div class="stars">
        <mat-icon
          *ngFor="let icon of maxRatingArr; let index = index"
         
          (click)="Rating(category, index)"
          [ngClass]="{ checked: isCategoryRatingHigher(category, index) }"
        >star</mat-icon>
      </div>
    </div>
  </div>

  <!-- Right column with 6 fields -->
  <div class="right-column">
    <div *ngFor="let category of categories.slice(4, 8)" class="category">
      {{ category }}:
      <div class="stars">
        <mat-icon
          *ngFor="let icon of maxRatingArr; let index = index"
          (mouseenter)="HandelMouseEnter(category, index)"
          (mouseleave)="HandelMouseLeave(category)"
          (click)="Rating(category, index)"
          [ngClass]="{ checked: isCategoryRatingHigher(category, index) }"
        >star</mat-icon>
      </div>
    </div>
  </div>

</div>

<div class="specificAchievement" >
  <textarea [(ngModel)]="specificAchievement" placeholder=" Specific Achievement:"></textarea>
</div>

<div class="specificAchievement" >
  <textarea [(ngModel)]="comment" placeholder=" Comment:"></textarea>
</div>

<button class="button1" style="margin-left: 450px; width: 137px; margin-top: 10px;background-color:#ce3262;" (click)="submitReview()">
  Submit
</button>
</div>