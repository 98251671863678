<div class="cover">
<div class="change-password-container">
  <h2>Change Password</h2>
  <p>Passwords must contain at least 6 letters, one symbol, and four numeric values.</p>

  <form class="change-password-form" (ngSubmit)="updatePassword()" #passwordForm="ngForm">
    <div class="form-field full-width">
      <label for="password">New Password</label>
      <input
        type="{{ hidePassword ? 'password' : 'text' }}"
        id="password"
        name="password"
        [(ngModel)]="user.password"
        required
        placeholder="Enter new password"
      />
      <button type="button" class="toggle-password" (click)="hidePassword = !hidePassword">
        <span class="icon" style="text-align: center;">{{ hidePassword ? '👁️' : '🙈' }}</span>
      </button>
    </div>

    <div class="form-field full-width">
      <label for="confirmPassword">Confirm Password</label>
      <input
        type="{{ hideConfirmPassword ? 'password' : 'text' }}"
        id="confirmPassword"
        name="confirmPassword"
        [(ngModel)]="user.confirmPassword"
        required
        placeholder="Confirm new password"
      />
      <button type="button" class="toggle-password" (click)="hideConfirmPassword = !hideConfirmPassword">
        <span class="icon">{{ hideConfirmPassword ? '👁️' : '🙈' }}</span>
      </button>
    </div>

    <div class="btn-pwd">
      <button type="submit" class="change-password-button">
        Change Password
      </button>
    </div>
  </form>
</div>
</div>