import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-show-email-popup',
  templateUrl: './show-email-popup.component.html',
  styleUrls: ['./show-email-popup.component.css']
})
export class ShowEmailPopupComponent implements OnInit {
  emailDetails = {
    toList: [],
    subject: '',
    body: '',
    ccList: [] as string[],
    bccList: [] as string[],
    attachmentList: [] as File[],
  };

  showCcInput = false;
  showBccInput = false;
  isSubmitting: boolean = false;

  // Form controls for selected employees
  ccControl = new FormControl();
  bccControl = new FormControl();
  ccAllSelected = false; // Track if all CC employees are selected
  bccAllSelected = false; // Track if all BCC employees are selected
  toControl =new FormControl();
  toAllSelected=false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      { name: 'quote', class: 'quote' },
      { name: 'redText', class: 'redText' },
      { name: 'titleText', class: 'titleText', tag: 'h1' },
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  user: any;

  constructor(private mainService: MainService, public dialogRef: MatDialogRef<ShowEmailPopupComponent>) { }

  ngOnInit(): void {  
    this.mainService.AllEmployeeProfileList().subscribe((res: any) => {
      this.user = res;
      console.log(JSON.stringify(this.user));

      // Initialize the selectedEmployeesControl with empty array
      this.ccControl.setValue([]);
      this.bccControl.setValue([]);
    });

    // Subscribe to changes in the CC and BCC controls
    this.ccControl.valueChanges.subscribe(selected => {
      this.ccAllSelected = selected.length === this.user.length; // Check if all are selected
    });
    
    this.toControl.valueChanges.subscribe(selected => {
      this.toAllSelected = selected.length === this.user.length; // Check if all are selected
    });
    this.bccControl.valueChanges.subscribe(selected => {
      this.bccAllSelected = selected.length === this.user.length; // Check if all are selected
    });
  }

  closeEmailPopup(): void {
    this.dialogRef.close();
  }

  sendEmail() {
    this.isSubmitting = true;
    const payload = {
      toList: [this.emailDetails.toList],
      subject: this.emailDetails.subject,
      body: this.emailDetails.body,
      cc: this.ccControl.value,
      bcc: this.bccControl.value,
      attachmentList: this.emailDetails.attachmentList,
    };

    console.log('Payload:', payload);

    // Upload all files before sending the email
    this.uploadAllFiles().then(() => {
      this.mainService.emailSendToUser(payload).subscribe(response => {
        Swal.fire({
          title: 'Success!',
          text: 'Email sent successfully',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.closeEmailPopup();
            window.location.reload();
          }
        });
      }, error => {
        Swal.fire('Error sending email', error);
      })
    });
  }

  async uploadAllFiles() {
    const folderName = 'emails';
    for (const file of this.emailDetails.attachmentList) {
      await this.uploadFile(file, folderName);
    }
  }

  uploadFile(file: File, folderName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(() => {
        resolve();
      }, (error) => {
        console.error('File upload error:', error);
        reject(error);
      });
    });
  }

  onCcSelectionChange(event: MatSelectChange) {
    this.emailDetails.ccList = event.value;
  }

  onToSelectionChange(event: MatSelectChange) {
    this.emailDetails.toList = event.value;
  }
  onBccSelectionChange(event: MatSelectChange) {
    this.emailDetails.bccList = event.value;
  }

  // toggleCcInput() {
  //   this.showCcInput = !this.showCcInput;
  // }

  // toggleBccInput() {
  //   this.showBccInput = !this.showBccInput;
  // }

  toggleSelectAllCc() {
    this.ccAllSelected = !this.ccAllSelected;
    this.ccControl.setValue(this.ccAllSelected ? this.user.map(u => u.userEmail) : []);
  }
  toggleSelectAllTO() {
    this.toAllSelected = !this.toAllSelected;
    this.toControl.setValue(this.toAllSelected ? this.user.map(u => u.userEmail) : []);
  }
  toggleSelectAllBcc() {
    this.bccAllSelected = !this.bccAllSelected;
    this.bccControl.setValue(this.bccAllSelected ? this.user.map(u => u.userEmail) : []);
  }

  removeAttachment(index: number): void {
    this.emailDetails.attachmentList.splice(index, 1);
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.emailDetails.attachmentList.push(files[i]);
    }
  }

  
}
