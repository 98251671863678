<div class="allContainer">
  <select id="userId" name="employeeId" [(ngModel)]="selectedUserId" (change)="onUserChange()" class="selector" style="float: right;">
    <option value="">Select Employee</option>
    <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.userName }}</option>
  </select>
  
  <div class="scrollable-content">
<div class="container">
  
  <div class="timeline-container col-md-7">
    <div class="timeline-header">
      <div  class="heading">Career Timeline</div>
      <div class="year-selector">
      </div>
    </div>
    <div class="timeline-content-1" #timelineContent>
      <div *ngFor="let timelinec of timeline" class="timeline-item">
        <p class="timeline-date">{{ timelinec.date | date: 'd MMM' }}</p>
        <div class="timeline-marker"></div>
        <h4 class="timeline-title">{{ timelinec.achievement }}</h4>
      </div>
    </div>
    
    
    
  </div>
  <div class="task-insights-container col-md-5" >
    
    <p  class="heading">Task Insights</p>
    <div class="content2">
    <div class="task-insights-content">
      <div class="task-insight" style="background-color: #FFBD59;" >
        <div class="task-icon" >
          <img src="../../../assets/square (1).png" alt="icon">
        </div>
        <div class="task-details">
          <p class="task-title">Assigned</p>
          <p class="task-count">{{data.assignedTasks}}</p>
        </div>
      </div>
      <div class="task-insight"  style="background-color: #6D9BFF;">
        <div class="task-icon" >
          <img src="../../../assets/square (3).png" alt="icon">
        </div>
        <div class="task-details">
          <p class="task-title">Done</p>
          <p class="task-count">{{data.completedTasks}}</p>
        </div>
      </div>
      <div class="task-insight"  style="background-color: #F67E71;">
        <div class="task-icon">
          <img src="../../../assets/square (2).png" alt="icon" style="    padding: 6px">
        </div>
        <div class="task-details">
          <p class="task-title">Pending</p>
          <p class="task-count">{{data.pendingTasks}}</p>
        </div>
      </div>
    </div>
    <div class="completion-rate">
      <p class="rate-title">On-time Completion Rate <span class="rate-value">{{data.onTimeTasks | number:'1.2-2'}} %</span></p>
    </div>
  </div>
  </div>
</div>
<div class="box1">
  <div class="heading">Performance Stats</div>
  
  <!-- Employee Dropdown -->
  
  
  <div class="container-fulid">
    <div class="row">
      <!-- Performance Circle Chart -->
      <div class="col-md-5">
        <div class="chart-container" style="height: 257px; width: 257px; margin-left: 108px;">
          <div class="progress-circle">
            <svg viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" fill="none" stroke="#BDBDBD" stroke-width="10"></circle>
              <circle cx="50" cy="50" r="45" fill="none" stroke-width="10"
                [attr.stroke-dasharray]="circleDasharray"
                [attr.stroke-dashoffset]="circleDashoffset"
                [attr.stroke]="strokeColor"></circle>
            </svg>
            <span class="progress-text" style="color: white;">
              <span style="color:white;font-size: 16px;font-weight: bold;">Obtained Score</span><br>
              {{ data.overAllPerformace | number:'1.0-0' }} %
            </span>
          </div>
        </div>  
      </div>

      <!-- Performance Data -->
      <div class="col-md-7">
        <div class="chart-container">
          <div class="data-list">
            <div class="data-item" *ngFor="let metric of ['teamWork', 'generalConduct', 'jobKnowledge', 'workQuality', 'skills', 'discipline','attendance']">
              <div class="data-label">{{ transform(metric) }}</div>
              <div class="data-bar">
                <div 
                  class="data-bar-fill" 
                  [ngStyle]="{
                    'width': ((report[0]?.[metric] ?? getDefaultReportValue(metric)) / 5) * 100 + '%'
                  }"
                ></div>
              </div>
              <div class="data-value">
                {{ ((report[0]?.[metric] ?? getDefaultReportValue(metric)) / 5) * 100 }}%
              </div>
            </div>
          </div> 
        </div>
        <div class="completion-rate">
          <p class="rate-title">
            Over All Performance 
            <span class="rate-value">
              {{ ( (report[0]?.obtainedScore / 40) * 100 ? (report[0]?.obtainedScore / 40) * 100 :  0) | number:'1.0-0' }} %
            </span>
          </p>
           
        </div>
      </div>
     
    </div>
  </div>
</div>
  <app-first-review [userId]="selectedUserId"></app-first-review>
  <app-second-review [userId]="selectedUserId"></app-second-review>
  <app-manager-growth [userId]="selectedUserId"></app-manager-growth>
</div>

</div>