<div class="full-page"><h4>Messages</h4>
<div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
  <h5>Message List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
  </div>
</div>

<div class="filter-form-wrapper" *ngIf="showFilterForm" >
  <!-- Close icon -->

  <div class="filter-btn">
    <h2 style="margin: 0;">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()" style="display: block; margin-top: 20px;">
    <!-- Date range -->
    <div class="form-group" >
      <label for="dateRange" >Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style=" margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
        />
      </div>
    </div>
    <div class="filter-btn">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
    </div>

  </form>

</div>

<div class="container2" >
  <div class="scrollable-content">
  <table>
    <thead>
      <tr>
        <th>UserIds</th>
        <th>Time</th>
        <th>Purpose</th>
        <th>Message</th>
        
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of filteredData" class="data-row">
        <td>{{ data.id }}</td>
        <td>{{ data.createdDate | date: 'dd-MMM-yyyy HH:mm a' }}</td>
        <td>{{ data.purpose }}</td>
        <!-- <td>{{ data. }}</td> -->
        <td>{{ data.message }}
          <ng-container *ngIf="data.image">
            <button (click)="openPopup(data.image)" style="background: none;    border: none;">
              <img src="../../../../assets/task.png" alt="Document Icon" width="20" height="20" style="background: none;">
            </button>
          </ng-container>
        </td>
        <!-- <td>
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>event</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let user of data.ChatUserss">
              {{ user.assignToName}}
            </button>
          </mat-menu>
        </td> -->
        <!-- <td [ngStyle]="{ backgroundColor: getStatusColor(data.status) }">
          {{ data.status }}
        </td> -->
        <!-- <td>
          <button mat-button [matMenuTriggerFor]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item [routerLink]="['/admin/update-meetings']" [queryParams]="{ id: data.id }">
              <mat-icon mat-list-icon>update</mat-icon>Update
            </button>
            <button mat-menu-item (click)="ondelete(data.id)">
              <mat-icon mat-list-icon>delete</mat-icon> Delete
            </button>
          </mat-menu>
        </td> -->
      </tr>
    </tbody>
  </table>
</div>
</div>
<div class="pagination-container">
  <div class="pagination-info">
    Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
  </div>
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>
</div>