<div class="full-page"><h4>Meetings</h4>

<div class="meetingContainer" >
  <form (ngSubmit)="formSubmit($event)" #meetingForm="ngForm" class="form-container">
    <div class="form-group">
      <label for="purpose">Purpose:</label>
      <input type="text" id="purpose" name="purpose" placeholder="Purpose" required />
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="date">Date:</label>
        <input type="date" id="date" name="date" required />
      </div>
      <div class="form-group col-md-6">
        <label for="date">Schedule Date:</label>
        <input type="time" id="meetingTime" name="meetingTime" required />
      </div>
    </div>
    <div class="form-group">
      <label for="description">Description:</label>
      <textarea type="text" id="description" name="description" required></textarea>
    </div>
    <div class="form-group">
      <label for="date">Location:</label>
      <input type="text" id="location" name="location" required />
    </div>
    <mat-form-field appearance="outline" class="w100 custom-mat-form-field">
      <mat-label style="color: white;">Select Employees</mat-label>
      <mat-select [formControl]="selectedEmployeesControl" multiple (selectionChange)="onSelectionChange($event)">
        <mat-option (click)="toggleSelectAll($event)">
          {{ allSelected ? 'Unselect All' : 'Select All' }}
        </mat-option>
        <mat-option *ngFor="let employee of data" [value]="employee.id">
          ID: {{ employee.id }} - Name: {{ employee.userName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button type="submit" class="submit-button">
      Submit
    </button>
  </form>
</div>
</div>