import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-salary',
  templateUrl: './update-salary.component.html',
  styleUrls: ['./update-salary.component.css']
})
export class UpdateSalaryComponent implements OnInit {
  userId: string;
  data: any;

  constructor(private http: HttpClient, private mainService: MainService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
    console.log("User ID: " + this.userId);

    this.mainService.showuserSalary(this.userId).subscribe(
      (res) => {
        this.data = res;
        console.log('Salary Data:', this.data);
      },
      (error) => {
        console.error('Error fetching salary data:', error);
      }
    );
  }

  updateUser() {
    // Using the bound data object directly instead of querying DOM elements
    let updatePayload = {
      userId: this.userId,
      basicSalary: this.data[0]?.basicSalary || null,
      hra: this.data[0]?.hra || null,
      medicalFund: this.data[0]?.medicalFund || null,
      convience: this.data[0]?.convience || null,
      bonus: this.data[0]?.bonus || null,
      incentive: this.data[0]?.incentive || null
    };

    console.log('Update Payload:', updatePayload);

    this.mainService.updateuser(this.userId, updatePayload).subscribe(
      (res: HttpResponse<any>) => {
        console.log('User updated successfully:', res);
        Swal.fire("Successfully updated.");
        this.router.navigate(['/admin/show-employee-salary']);
      },
      (error) => {
        console.error('Error updating user:', error);
        Swal.fire('Error', error.error.message || 'Error updating salary.', 'error');
      }
    );
  }
}
