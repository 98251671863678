import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SidebarService } from '../../../app/services/sidebar.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isLoggedIn = false;
  user = null;
  currentDate: Date;
  hideSidebar: boolean = true;
  constructor(public login: LoginService, private router: Router,private sidebarService: SidebarService) {}

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }
  ngOnInit(): void {
    this.isLoggedIn = this.login.isLoggedIn();
    this.user = this.login.getUser();
    this.login.loginStatusSubject.asObservable().subscribe((data) => {
      this.isLoggedIn = this.login.isLoggedIn();
      this.user = this.login.getUser();
    });
  }

  public logout() 
  {
    Swal.fire({ title: 'Confirm Sign Off', text: 'Are you sure you want to sign off?', icon: 'question',showCancelButton: true,confirmButtonText: 'Yes, sign off',  confirmButtonColor: '#ff4081',cancelButtonText: 'Cancel'}).then((result) => {
      if (result.isConfirmed) {
        this.login.logout();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    });
  }
  form()
  {
    if (this.login.getUserRole() == 'ADMIN') 
      {
      this.router.navigate(['admin']);
      this.login.loginStatusSubject.next(true);
      }
      else if (this.login.getUserRole() == 'USER') 
      {
      this.router.navigate(['user-dashboard']);
      this.login.loginStatusSubject.next(true);
      }
      else if (this.login.getUserRole() == 'MANAGER') 
      {
      this.router.navigate(['manager-dashboard']);
      this.login.loginStatusSubject.next(true);
      }
}
}
