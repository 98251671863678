<div class="container" style="height: 100vh;">
  <div class="row">
    <div class="col-md-5">
      <div class="firstContainer">
        <div class="col-md-12">
          <div class="filter-container">
            <form (ngSubmit)="applyFilters()">
              <div class="row">
                <div class="col-md-4">
                  <select id="userId" [(ngModel)]="userId" name="userId">
                    <option value="" disabled selected>Select User</option>
                    <option *ngFor="let user of users" [value]="user.id" >{{ user.userName}}</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <input type="date" id="startDate" [(ngModel)]="startDate" name="startDate" placeholder="Start Date">
                </div>
                <div class="col-md-4">
                  <input type="date" id="endDate" [(ngModel)]="endDate" name="endDate" placeholder="End Date">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <button type="submit" class="btn btn-primary"> <i class="fas fa-filter" style="margin-right: 5px;"></i></button>
                </div>
              </div>
            </form>
            </div>
        </div>
        <div class="show message">
          <div class="row">  
            <div class="col-md-12" style="max-height: 450px; overflow-y: auto;">
              <div class="profile" *ngFor="let emailDetail of emailDetails" (click)="showEmailDetails(emailDetail.id)">
               
                <div class="showMessage" style="display: flex;" >
                  <span>
                  <img [src]="emailDetail.senderprofile" alt="{{ emailDetail.senderName }}'s profile image">
                </span>
                <span style="width: 100%;margin-left: 20px;">
                  <p class="date">{{ emailDetail.createdDate | date:'d MMMM yyyy h:mm a' }}</p>
                  <h2 style="margin-top: 10px;">{{ emailDetail.senderName }}</h2>
                  <p class="subject">{{ emailDetail.subject }}</p>
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <button class="btn btn-success" (click)="openEmailPopup()" style="background: #161515; border: 2px solid #161515; float: right; z-index: 1000; cursor:pointer">
        <img src="../../../assets/add-icon2.png" alt="add-email"/><span></span>
      </button>
      <div class="containerMessage" *ngIf="selectedEmailDetail">
        <span class="close" (click)="closeMessage()">&times;</span>
        <div class="from" style="display: flex; color: white;">From <span>{{selectedEmailDetail.senderEmail}}</span></div>
        <div class="to" style="display: flex; color: white;">
          To <span>{{selectedEmailDetail.recipientEmail}}</span>
          <span *ngIf="selectedEmailDetail.cc || selectedEmailDetail.bcc" class="arrow" (click)="toggleCcBccPopup($event)">&#9660;</span>
        </div>
        <div class="cc-bcc-popup" *ngIf="showCcBccPopup">
          <div *ngIf="selectedEmailDetail.cc">CC: <span>{{selectedEmailDetail.cc}}</span></div>
          <div *ngIf="selectedEmailDetail.bcc">BCC: <span>{{selectedEmailDetail.bcc}}</span></div>
        </div>
        <div class="message" style="display: flex;     color: white;
        gap: 10px;
        text-transform: capitalize;
    ">
          Subject <span>   {{selectedEmailDetail.subject}}</span>
        </div>
        <div class="message-content" >
          <div class="message-content">
            {{ selectedEmailDetail.message }}
            <div class="attachment" *ngIf="selectedEmailDetail.fileName">
              <p>Attachments:</p>
              <div *ngFor="let file of selectedEmailDetail.fileName.split(', ')">
                <a [href]="file" target="_blank">
                  <img [src]="file" alt="Attachment" style="max-width: 100px; max-height: 100px;">
                </a>
              </div>
            </div>
          </div>
        </div>
        <p>Thanks & Regards,</p>
        <p>{{selectedEmailDetail.senderName}}</p>
      </div>
    </div>
    <div *ngIf="showCcBccPopup" class="overlay" (click)="closeCcBccPopup()"></div>
  </div>
</div>

