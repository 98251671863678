<mat-dialog-content>
  <div (click)="closeModal()"></div>
 <div class="modal-content">
    <span class="close" (click)="closeModal()" style="color: white; text-align: right;font-size: 24px; cursor:pointer">&times;</span>
    <h2>Add Project</h2>
    <!-- {{isUpdating ? 'Update Project' : 'Add Project'}} -->
    <form (ngSubmit)="formSubmit($event)">
      <label for="heading">Heading</label>
      <input type="text" id="heading" name="heading" [(ngModel)]="user.heading" required>

      <label for="subject">Subject</label>
      <input type="text" id="subject" name="subject" [(ngModel)]="user.subject" required>

      <label for="description">Description</label>
      <textarea id="description" name="description" [(ngModel)]="user.description"
        placeholder="Enter project description" required></textarea>

        <label for="url">Banner Image</label>
        <input type="file" id="url" (change)="onBannerImageChange($event)"  [(ngModel)]="user.banner" style="width: 100%;" accept="image/*">
  
      <label for="employee">Select Employee</label>
      <!-- <select id="employee" name="employee" [(ngModel)]="selectedEmployee">
        <option *ngFor="let employee of data" [ngValue]="employee">{{ employee.userName }}</option>
      </select> -->
      <mat-form-field class="white-text w100" appearance="outline">
        <mat-select [formControl]="selectedEmployeesControl" multiple (selectionChange)="onSelectionChange($event)" style="color:white">
          <mat-option (click)="toggleSelectAll($event)">
            {{ allSelected ? 'Unselect All' : 'Select All' }}
          </mat-option>
          <mat-option *ngFor="let employee of data" [value]="employee.id" >
            ID: {{ employee.id }} - Name: {{ employee.userName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ngx-dropzone (change)="addSelectedFiles($event)" [multiple]="true">
        <ngx-dropzone-label>Drop files here or click to select files</ngx-dropzone-label>
        <div class="dropzone-previews">
          <div class="preview" *ngFor="let preview of filePreviews; let i = index">
            <img [src]="preview.url" [alt]="preview.name">
            <p>{{ preview.name }}</p>
            <button type="button" (click)="removeFile(i)">Remove</button>
          </div>
        </div>
      </ngx-dropzone>
      <label for="status">Status</label>
      <select id="status" name="status" [(ngModel)]="user.status" required>
        <option value="PENDING">Pending</option>
        <option value="REVIEWED">Reviewed</option>
        <option value="DELAYED">Delayed</option>
        <option value="NOTUSED">Not Used</option>
        <option value="INPROGRESS">Inprogress</option>
        <option value="DONE">Done</option>
      </select>

      <label for="priorityLevel">Priority Level</label>
      <select id="priorityLevel" name="priorityLevel" [(ngModel)]="user.priorityLevel" required>
        <option value="LOW">Low</option>
        <option value="MEDIUM">Medium</option>
        <option value="HIGH">High</option>
      </select>

      <label for="deadline">Deadline</label>
      <input type="date" id="deadlineDate" name="deadlineDate" [(ngModel)]="user.deadlineDate" required>
      <label for="deadline">Deadline</label>
      <input type="time" id="deadlineTime" name="deadlineTime" [(ngModel)]="user.deadlineTime" required>

      <button type="submit">Add Project</button>
    </form>
  </div>
</mat-dialog-content>