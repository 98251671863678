import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/MyService/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-deduction',
  templateUrl: './deduction.component.html',
  styleUrls: ['./deduction.component.css']
})
export class DeductionComponent implements OnInit {

  constructor(private route: ActivatedRoute,private mainService:MainService,private snackBar:MatSnackBar) { }
 deduction:any=
  {
    commentsForSalary : '',
    deduction:''
}

ngOnInit(): void {
  
}
submitDeductionForm(){
  const userId = this.route.snapshot.paramMap.get('id')
  if(userId){
    this.mainService.addDeduction(userId,this.deduction).subscribe((res:any)=>{
      this.showSnackBar('Deduction added successfully');
    },(error:any)=>{
      this.showSnackBar('Error while adding deduction');
    }
  )
  }else {
    console.error('User ID is null or undefined');
  }
}
private showSnackBar(message: string): void {
  this.snackBar.open(message, 'Close', {
    duration: 3000,
  });
}
}
