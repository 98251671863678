import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
  id = localStorage.getItem('id');
  isSubmitting = false;
  data: any;
  selectedFiles: File[] = [];
  filePreviews: { name: string; url: string }[] = []; 
  selectedEmployeesControl = new FormControl();
  allSelected: boolean = false;

  public user = {
    projectName: '',
    heading: '',
    subject: '',
    description: '',
    url: '',
    status: '',
    deadLine: '',
    fileUrl: '',
    memberIds: [] as number[],
    files: [] as string[],
    priorityLevel: '',
    banner: '',
    deadlineDate: '',
    deadlineTime: ''
  };

  constructor(
    private mainService: MainService,
    private router: Router,
    private dialogRef: MatDialogRef<AddProjectComponent>
  ) {}

  ngOnInit(): void {
    this.mainService.managerAlllist(this.id, true).subscribe((res: any) => {
      this.data = res;
      console.log("Employee Data:", this.data);
    });
  }

  extractFileName(url: string): string {
    return url.split('/').pop() || '';
  }

  onBannerImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          const folderName = 'messages';
          const response: any = await this.mainService.uploadFile(file, folderName).toPromise();
          this.user.banner = response.message; 
        } catch (error) {
          Swal.fire('Error', 'Banner upload failed.', 'error');
        }
      };
      reader.readAsDataURL(file);
    }
  }

  addSelectedFiles(event: any): void {
    const files: File[] = Array.from(event.addedFiles);
    this.selectedFiles.push(...files);
    this.filePreviews.push(...files.map(file => ({
      name: file.name,
      url: URL.createObjectURL(file)
    })));
  }

  async formSubmit(event: Event): Promise<void> {
    event.preventDefault();
    let fileUrls: string[] = [];
  
    // Check if deadline is provided
    if (!this.user.deadlineDate || !this.user.deadlineTime) {
      Swal.fire('Error', 'Please fill in both the deadline date and time.', 'error');
      this.isSubmitting = false;
      return;
    }
  
    const deadlineTimestamp = new Date(`${this.user.deadlineDate}T${this.user.deadlineTime}`).getTime();
    this.user.deadLine = deadlineTimestamp.toString(); 
  
    if (this.selectedFiles.length > 0) {
      try {
        fileUrls = await this.uploadFiles();
      } catch (error) {
        Swal.fire('Error', 'File upload failed.', 'error');
        return;
      }
    }
    console.log('Form data before submission:', this.user);
    console.log('Selected member IDs:', this.user.memberIds);
    
    this.saveFormData(fileUrls);
  }

  async uploadFiles(): Promise<string[]> {
    const folderName = 'messages';
    const fileUrls: string[] = [];
    for (const file of this.selectedFiles) {
      try {
        const response: any = await this.mainService.uploadFile(file, folderName).toPromise();
        fileUrls.push(response.message); 
      } catch (error) {
        console.error('File upload failed:', error);
        throw new Error('File upload failed');
      }
    }
    return fileUrls;
  }

  saveFormData(fileUrls: string[]): void {
    this.user.files = fileUrls;
    // Ensure memberIds is correctly assigned
    this.user.memberIds = this.selectedEmployeesControl.value || [];
    const userId = this.id;
    console.log('Member IDs before submission:', this.user.memberIds); // Debugging
    this.mainService.savAssignment(userId, this.user).subscribe(
      (res) => {  
        Swal.fire({title:'Success', text:'Form submitted successfully',icon: 'success',confirmButtonText: 'OK'}).then((result)=>{
          if(result.isConfirmed){
            window.location.reload();
          }
        })
        this.router.navigate(['manager-dashboard/project-dashboard']);
        this.isSubmitting = false;
      },
      (error) => {
        Swal.fire('Error', 'Failed to submit the form', 'error');
        this.isSubmitting = false;
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
    window.location.reload();
  }

  toggleSelectAll(event: MouseEvent): void {
    event.stopPropagation(); // Prevent the dropdown from closing
  
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      const allEmployeeIds = this.data.map((emp: any) => emp.id); // Get all employee IDs
      this.selectedEmployeesControl.setValue(allEmployeeIds); // Select all employees
      this.user.memberIds = allEmployeeIds; // Update memberIds
    } else {
      this.selectedEmployeesControl.setValue([]); // Clear selections
      this.user.memberIds = []; // Clear memberIds
    }
    console.log('All Selected:', this.allSelected);
    console.log('Member IDs after toggle:', this.user.memberIds);
  }

  onSelectionChange(event: any): void {
    const selectedValues = this.selectedEmployeesControl.value || [];
    console.log('Selected Values:', selectedValues); 

    this.allSelected = selectedValues.length === this.data.length;
    this.user.memberIds = selectedValues; // Assign directly if they are IDs
    console.log('Member IDs after selection change:', this.user.memberIds);
  }

  removeFile(index: number): void {
    URL.revokeObjectURL(this.filePreviews[index].url);
    this.selectedFiles.splice(index, 1);
    this.filePreviews.splice(index, 1);
  }
}
