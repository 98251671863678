import { DatePipe } from '@angular/common';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { MainService } from 'src/app/MyService/main.service';
@Component({
  selector: 'app-growth',
  templateUrl: './growth.component.html',
  styleUrls: ['./growth.component.css']
})
export class GrowthComponent implements OnInit {
  userId=localStorage.getItem('id')
    data: any;
    review: any = {};
    momData: any[] = [];
    barChartData: any[] = []; 
    month: any;
  
    showXAxis = true;
    showYAxis = true;
    showLegend = true;
    showXAxisLabel = true;
    showYAxisLabel = true;
    xAxisLabel = 'Months';
    yAxisLabel = 'Score';
    animations = true;
    showGridLines = true;
    roundDomains = true;
    rotateXAxisTicks = true;
    referenceLines = true;
    useGradients = false;
    wrapTicks = true;
    
    colorScheme = {
      domain: ['rgba(89, 50, 234, 1)', 'rgba(234, 76, 76, 1)', 'rgba(76, 234, 133, 1)']
    };
  
    constructor(private mainService: MainService, private datePipe: DatePipe) {}
  
    ngOnInit(): void {
      this.loadReviewData();
    }
  
    loadReviewData() {
      this.mainService.performance(this.userId, this.month).subscribe(
        (res: any) => {
          this.review = res;
          this.momData = res.mom || []; 
          this.prepareChartData(); 
        },
        (error) => {
          console.error("Error fetching review data:", error); 
        }
      );
    }
  
    prepareChartData() {
      if (this.momData && this.momData.length > 0) {
        this.barChartData = [
          {
            "name": "Performance Score",
            "series": this.momData.map((item: any) => {
              const month = this.datePipe.transform(item.month, 'MMM yyyy');
              return {
                name: month,
                value: item.score
              };
            })
          }
        ];
      } else {
        this.barChartData = [];  // Empty state for the chart
      }
    }
  }
  