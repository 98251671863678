import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/MyService/main.service';
import { AdminVerifiedComponent } from '../admin-verified/admin-verified.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-document-show',
  templateUrl: './admin-document-show.component.html',
  styleUrls: ['./admin-document-show.component.css'],
})
export class AdminDocumentShowComponent implements OnInit {
  urls: any = {
    documents: [],
    documentVerified: false
  };
  user:any;
  userId: string | null = localStorage.getItem('user_id');
  popupVisible = false;
  popupImageUrl = '';
  userName: any;
  email: any;
  modalVisible: boolean;

  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}
  
  ngOnInit(): void {
    this.userId = this.route.snapshot.queryParams['id'];
    this.userName = this.route.snapshot.queryParams['name'];
    this.email = this.route.snapshot.queryParams['email'];
    
    this.fetchUserDocs();
  }
  fetchUserDocs() {
    if (this.userId) {
      this.mainService.showDocs(this.userId).subscribe(
        (res: any) => {
          this.urls.documents = res.urls; 
          this.user = res;
          // console.log("User Documents: " + JSON.stringify(this.urls.documents, null, 2));  
          // console.log("Full Response: " + JSON.stringify(res, null, 2));
        },
        (error) => {
          console.error('Error fetching documents:', error);
          Swal.fire('Error', ' ' + error.error.message, 'error');
        }
      );
    }
  }
  

  getImageAlt(documentUrl: string): string {
    const fileName = documentUrl.split('/').pop();
    const fileNameWithoutExtension = fileName?.split('.')[0];
    return fileNameWithoutExtension || ''; 
  }

  downloadFile(fullUrl: string, fileName: string) {
    const fileUrl = fullUrl.split('/user/image/')[1];
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  openImagePopup(imageUrl: string) {
    this.popupImageUrl = imageUrl;
    this.popupVisible = true;
  }

  openModal() {
    this.modalVisible = true;
  }

  openDialog(userId: string | null) {
    const dialogRef = this.dialog.open(AdminVerifiedComponent, {
      data: { userId: userId }, 
    });
  }

  async downloadImage(imageUrl: string, imageName: string) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = imageName; 
      link.style.display = 'none'; 
      document.body.appendChild(link);
      link.click(); 
      document.body.removeChild(link); 
      window.URL.revokeObjectURL(url); 
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }
}
