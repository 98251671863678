<div class="full-page"><h4>Upload Documents</h4>
<div class="upload-container container2" *ngIf="!documentVerified"  style="margin-top: 55px; background-color: white;">
  <!--  -->
<div class="container">
<p>Drop Your Documents Here...</p>
<div class="row">

<div class="col-md-3">
<div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('aadhar', $event)">
<ngx-dropzone-label>

<mat-icon>cloud_upload</mat-icon><br>
Drop Aadhar Image</ngx-dropzone-label>
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.aadhar" [file]="f" [removable]="true" (removed)="onRemove('aadhar', f)">
<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</div>
</div>
<div class="col-md-3">
<div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('pan', $event)">
<ngx-dropzone-label>
<mat-icon>cloud_upload</mat-icon><br>Drop Pan Image</ngx-dropzone-label>
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.pan" [file]="f" [removable]="true" (removed)="onRemove('pan', f)">
<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</div>
</div>
<div class="col-md-3">
<div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('metriculation', $event)">
<ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br> Drop metriculation Image</ngx-dropzone-label>
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.metriculation" [file]="f" [removable]="true" (removed)="onRemove('metriculation', f)">
<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</div>
</div>
<div class="col-md-3">
<div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('intermediate', $event)">
<ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Intermediate Image</ngx-dropzone-label>
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.intermediate" [file]="f" [removable]="true" (removed)="onRemove('intermediate', f)">
<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</div>
</div>
<div class="col-md-3">
<div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('grauduation', $event)">
<ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Grauduation Image</ngx-dropzone-label>
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.grauduation" [file]="f" [removable]="true" (removed)="onRemove('grauduation', f)">
<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</div>
</div>
<div class="col-md-3">
<div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('postGraduation', $event)">
<ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Post Graduation Image</ngx-dropzone-label>
<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.postGraduation" [file]="f" [removable]="true" (removed)="onRemove('postGraduation', f)">
<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
</ngx-dropzone-image-preview>
</div>
</div>

<div class="col-md-3">
<div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('experience', $event)">
  <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Experience Letter Image</ngx-dropzone-label>
  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.experience" [file]="f" [removable]="true" (removed)="onRemove('experience', f)">
    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>
</div>
</div>

<div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('lastCompanyOfferLetter', $event)">
    <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Last Company Offer Letter Image</ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.lastCompanyOfferLetter" [file]="f" [removable]="true" (removed)="onRemove('lastCompanyOfferLetter', f)">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </div>
  </div>
  <div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('salarySlip', $event)">
    <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Salary Slip Image</ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.salarySlip" [file]="f" [removable]="true" (removed)="onRemove('salarySlip', f)">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </div>
  </div>
  <div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('appointmentLetter', $event)">
    <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Appointment Letter Image</ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.appointmentLetter" [file]="f" [removable]="true" (removed)="onRemove('appointmentLetter', f)">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-image-preview>
  </div>
  </div>
  </div>
  </div>
  
  <button  mat-raised-button color="accent" style="float: right;margin-top: -72px;margin-right: 435px;" (click)="uploadFiles()">Upload  Files</button>
</div>
<div class="upload-container container2" *ngIf="documentVerified" style="margin-top: 55px; background-color: white;">
  <div class="container">
  <p>Drop Your Documents Here...</p>
  <div class="row">
  
  <div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('doc1', $event)">
  <ngx-dropzone-label>
  
  <mat-icon>cloud_upload</mat-icon><br>
  Drop  Image</ngx-dropzone-label>
  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.doc1" [file]="f" [removable]="true" (removed)="onRemove('doc1', f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>
  </div>
  </div>
  <div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('doc2', $event)">
  <ngx-dropzone-label>
  <mat-icon>cloud_upload</mat-icon><br>Drop  Image</ngx-dropzone-label>
  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.doc2" [file]="f" [removable]="true" (removed)="onRemove('doc2', f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>
  </div>
  </div>
  <div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('doc3', $event)">
  <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br> Drop  Image</ngx-dropzone-label>
  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.doc3" [file]="f" [removable]="true" (removed)="onRemove('doc3', f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>
  </div>
  </div>
  <div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('doc4', $event)">
  <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop  Image</ngx-dropzone-label>
  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.doc4" [file]="f" [removable]="true" (removed)="onRemove('doc4', f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>
  </div>
  </div>
  <div class="col-md-3">
  <div ngx-dropzone  class="custom-dropzone"  (change)="onSelect('doc5', $event)">
  <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop  Image</ngx-dropzone-label>
  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files.doc5" [file]="f" [removable]="true" (removed)="onRemove('doc5', f)">
  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-image-preview>
  </div>
  </div> 
  </div>
  </div>
    
    <button  mat-raised-button color="accent" style="float: right;margin-top: -72px;margin-right: 435px;" (click)="updateFiles()">Upload  Files</button>
  </div>
</div>