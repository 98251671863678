<div class="bootstrap-wrapper">
  <div>
    <div class="row">
      <div
        class="col-md-2 sidebar"
        [ngClass]="{'hidden-sidebar': !hideSidebar}" 
        style="margin-top: 54px;"
      >
        <app-sidebar *ngIf="hideSidebar" style="width:17%"></app-sidebar> 
      </div>
      <div
        class="col-md-10 content"
        [ngClass]="{'full-width': !hideSidebar}" 
        style="background: #161515; transition: width 0.3s ease;margin-top:60px"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
