import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-assignment',
  templateUrl: './user-assignment.component.html',
  styleUrls: ['./user-assignment.component.css']
})
export class UserAssignmentComponent implements OnInit {
  id=localStorage.getItem('id')
  status: number;
  startDate: number;
  endDate: string;
  pageNumber: any;
  pageSize: any;
  projectdetails:any;
 
  constructor(private http: HttpClient, private mainService: MainService,private router:Router) {}

  ngOnInit(): void {
  this.getProjectDetails();
  }
  getProjectDetails(){
    this.mainService.getAllAssigmentbyUser(this.id,this.status,this.startDate,this.endDate,this.pageNumber,this.pageSize).subscribe((project:any)=>{
      this.projectdetails=project.content;
      console.log("res"+ JSON.stringify(project));
    })
  }
}