import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-verified',
  templateUrl: './admin-verified.component.html',
  styleUrls: ['./admin-verified.component.css']
})
export class AdminVerifiedComponent implements OnInit {
  documentVerified: boolean;
  epmloyeeId:any;

  constructor(
    private mainService: MainService,
    private dialogRef: MatDialogRef<AdminVerifiedComponent>,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.epmloyeeId = this.route.snapshot.queryParams['id'];
  }

  submitVerification(): void {
    this.mainService.isVerified(this.epmloyeeId, this.documentVerified).subscribe(
      (res: any) => {
        Swal.fire("User Verified Successfully"); 
        this.dialogRef.close(true);
        // Refresh the page
        location.reload();
      },
      (error) => {
        console.error('Error Verifying', error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
        // Optionally, you can handle errors here
      }
    );
  }
  

  closeModal(): void {
    this.dialogRef.close(false);
  }
}
