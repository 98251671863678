<div class="full-page"><h4> Announcement</h4>
    <form [formGroup]="form" (ngSubmit)="formSubmit()">
      <div>
        <label for="subject">Subject:</label>
        <input type="text" id="subject" formControlName="subject">
      </div>
      <div>
        <label for="description">Description:</label>
        <textarea id="description" formControlName="description"></textarea>
      </div>
      <div class="row" style=" margin-top:10px;">
        <div class="col">
          <label>Event Date:</label>
          <input type="date" id="eventDate" name="deadlineDate" formControlName="eventDate">
        </div>
        <div class="col">
          <label>Event Time:</label>
          <input type="time" id="eventTime" name="deadlineTime" formControlName="eventTime">
        </div>
      </div>
      <div ngx-dropzone class="custom-dropzone" (change)="onSelect($event)"  style="height: 100px;margin-top: 20px;">
        <ngx-dropzone-label><mat-icon>cloud_upload</mat-icon><br>Drop Task Files</ngx-dropzone-label>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngIf="file" [file]="file" [removable]="true" (removed)="onRemove($event)">
          <ngx-dropzone-label>{{ file.name }}</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      </div>
      <!-- <button type="submit" style="margin-top: 20px;">Submit</button> -->
      <div class="container text-center mt20">
        <button mat-raised-button color="accent" type="submit" >Add</button>
      </div>
    </form>
  </div>
  