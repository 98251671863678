import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
@Component({
  selector: 'app-event-chart',
  templateUrl: './event-chart.component.html',
  styleUrls: ['./event-chart.component.css'],
})
export class EventChartComponent implements OnInit {
  department: string;
  piedata: any;
  barChartData: any[] = [];
  performers: any;
  showXAxis = true;
  showYAxis = true;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = 'Months';
  yAxisLabel = 'Total Tasks';
  colorScheme = {
    domain: ['rgba(89, 50, 234, 1)', 'rgba(234, 76, 76, 1)', 'rgba(76, 234, 133, 1)']
  };
  data:any;
  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    this.monthOnMonth();
    this.performer();
  }
  monthOnMonth() {
    this.mainService.taskgraph().subscribe((res: any) => {
      this.data = res;
      this.barChartData = this.data.map((item: any) => {
        return {
          "name": item.Month,
          "series": [
            { "name": "Completed Tasks", "value": item["Completed Tasks"] },
            { "name": "Initialized Tasks", "value": item["Initialized Tasks"] },
            { "name": "Pending Tasks", "value": item["Pending Tasks"] }
          ]
        };
      });

      console.log("Graph Data:", JSON.stringify(this.barChartData));
    });
  }

  performer() {
    this.mainService.topPerformer().subscribe((res: any) => {
      this.performers = res.content;
      console.log("Performers:", JSON.stringify(this.performers));
    });
  }
}
