import { Component } from '@angular/core';
import { SidebarService } from '../../../services/sidebar.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    trigger('sidebarAnimation', [
      state('true', style({
        marginLeft: '0',
      })),
      state('false', style({
        marginLeft: '250px',
      })),
      transition('true <=> false', animate('0.3s ease')),
    ]),
  ],


})

export class DashboardComponent {
  hideSidebar: boolean = true;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    this.sidebarService.isSidebarHidden().subscribe((hidden) => {
      this.hideSidebar = hidden;
    });
  }
  toggleSidebar() {
    this.hideSidebar = !this.hideSidebar;  
  }
}
