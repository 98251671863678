<mat-dialog-content>
  <div class="modal-overlay" (click)="closeModal()"></div>
  <div class="modal-content">
      <span class="close" style="float: right;cursor: pointer;" (click)="closeModal()">&times;</span>
      <div class="popup-content">
        <form (ngSubmit)="formSubmit($event)">
          <div class="task-info">
            <label for="Task Id" style="font-weight: 500;">Today Task Id</label>
            <button mat-mini-fab type="button" id="id" name="id" [value]="taskId" disabled style="margin-left:20px;color: white;background-color: #ff4081;">{{ taskId }}</button>
          </div>
          <mat-form-field class="w100" appearance="outline" style="margin-top: 34px;background: white!important;">
            <mat-label>Comment</mat-label>
            <textarea required autofocus type="text" id="comments" name="Comment" matInput  placeholder="Enter here" rows="14" ></textarea>
          </mat-form-field>
          <div class="container text-center mt15">
            <button mat-raised-button color="accent" type="submit">Submit</button>
          </div>
        </form>
        <form (ngSubmit)="onfromSubmit($event)" *ngIf="isAdmin()">
          <mat-form-field class="w100" appearance="outline" style="margin-top: 16px">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="user.status" id="status" name="status">
              <mat-option value="PENDING">PENDING</mat-option>
              <mat-option value="DONE">DONE</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="container text-center mt15">
            <button mat-raised-button color="accent" type="submit">Add</button>
          </div>
        </form>
      </div>
  </div>
</mat-dialog-content>


