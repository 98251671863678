import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { WelcomeComponent } from './pages/admin/welcome/welcome.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UserDashboardComponent } from './pages/user/user-dashboard/user-dashboard.component';
import { AdminGuard } from './services/admin.guard';
import { NormalGuard } from './services/normal.guard';
import { ShowEmployeeSalaryComponent } from './pages/admin/show-employee-salary/show-employee-salary.component';
import { UpdateSalaryComponent } from './pages/admin/update-salary/update-salary.component';
// import { EmployeeAttendanceComponent } from './pages/admin/employee-attendance/employee-attendance.component';
import { AllEmployeeProfileComponent } from './pages/admin/all-employee-profile/all-employee-profile.component';
import { AddSalaryComponent } from './pages/admin/add-salary/add-salary.component';
import { AddUserProfileComponent } from './pages/admin/add-user-profile/add-user-profile.component';
import { UpdateUserProfileComponent } from './pages/admin/update-user-profile/update-user-profile.component';
import { SingleEmployeeAttendanceComponent } from './pages/admin/single-employee-attendance/single-employee-attendance.component';
import { ShowLeaveRequestComponent } from './pages/admin/show-leave-request/show-leave-request.component';
import { ShowuserTaskComponent } from './pages/admin/showuser-task/showuser-task.component';
import { AddCommentComponent } from './pages/admin/add-comment/add-comment.component';
import { DialogLeaveComponent } from './pages/admin/dialog-leave/dialog-leave.component';
import { CalenderComponent } from './pages/calender/calender.component';
import { ShowAllApprovedLeaveComponent } from './pages/admin/show-all-approved-leave/show-all-approved-leave.component';
import { EmailComponent } from './pages/admin/email/email.component';
// import { EmailComponent as EmailManagerComponent } from './pages/manager/email/email.component';
import { MeetingsComponent } from './pages/admin/meetings/meetings.component';
import { SaveCandidateComponent } from './pages/admin/save-candidate/save-candidate.component';
import { ShowMeetingsComponent } from './pages/admin/show-meetings/show-meetings.component';
import { UpdateMeetingComponent } from './pages/admin/update-meeting/update-meeting.component';
import { AllCandidateComponent } from './pages/admin/all-candidate/all-candidate.component';
import { UpdateCandidateComponent } from './pages/admin/update-candidate/update-candidate.component';
import { CalendarInviteComponent } from './pages/admin/calendar-invite/calendar-invite.component';
import { UserTaskComponent } from './pages/admin/user-task/user-task.component';
import { AboutDeveloperComponent } from './pages/admin/about-developer/about-developer.component';
import { PreviousTaskComponent } from './pages/admin/previous-task/previous-task.component';
// import { AdminAssignmentComponent } from './pages/admin/admin-assignment/admin-assignment.component';
// import { AdminCommentAssignmentComponent } from './pages/admin/admin-comment-assignment/admin-comment-assignment.component';
// import { PreviousAssignmentComponent } from './pages/admin/previous-assignment/previous-assignment.component';
import { SingleAssignmentComponent } from './pages/admin/single-assignment/single-assignment.component';
import { HRAttendanceComponent } from './pages/admin/hrattendance/hrattendance.component';
import { UserGoogleMapComponentComponent } from './pages/admin/user-google-map-component/user-google-map-component.component';
import { SalesAttendanceComponent } from './pages/admin/sales-attendance/sales-attendance.component';
import { EventChartComponent } from './pages/admin/event-chart/event-chart.component';
import { ForgetPasswordAdminComponent } from './pages/admin/forget-password-admin/forget-password-admin.component';
import { AdminDocumentShowComponent } from './pages/admin/admin-document-show/admin-document-show.component';
import { UserRatingComponent } from './pages/admin/user-rating/user-rating.component';
import { ShowUserReviewComponent } from './pages/admin/show-user-review/show-user-review.component';
import { ShowUserRatingsComponent } from './pages/admin/show-user-ratings/show-user-ratings.component';
import { AssignTaskComponent } from './pages/admin/assign-task/assign-task.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { UpdateAssignmentComponent } from './pages/user/update-assignment/update-assignment.component';
import { ReviewComponent } from './pages/user/review/review.component';
import { DeveloperComponent } from './pages/user/developer/developer.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { AttendanceComponent } from './pages/user/attendance/attendance.component';
import { TaskComponent } from './pages/user/task/task.component';
import { AddTaskComponent } from './pages/user/add-task/add-task.component';
import { UserSalaryComponent } from './pages/user/user-salary/user-salary.component';
import { UserMeetingComponent } from './pages/user/user-meeting/user-meeting.component';
import { OnuserEndLeaveComponent } from './pages/user/onuser-end-leave/onuser-end-leave.component';
import { UserLeaveRequestComponent } from './pages/user/user-leave-request/user-leave-request.component';
import { UpdateTaskComponent } from './pages/user/update-task/update-task.component';
import { UserAssignmentComponent } from './pages/user/user-assignment/user-assignment.component';
import { UserAssignComponent } from './pages/user/user-assign/user-assign.component';
import { ForgetPasswordUserComponent } from './pages/user/forget-password-user/forget-password-user.component';
import { UserDocumentComponent } from './pages/user/user-document/user-document.component';
import { UserWelcomeComponent } from './pages/user/user-welcome/user-welcome.component';
import { FeedbackComponent } from './pages/user/feedback/feedback.component';
import { GoogleMapComponent } from './pages/user/google-map/google-map.component';
import { MarkAttendanceComponent } from './pages/user/mark-attendance/mark-attendance.component';
import { NewAnnouncementComponent } from './pages/admin/new-announcement/new-announcement.component';
import { SalaryCycleComponent } from './pages/admin/salary-cycle/salary-cycle.component';
import { AdminAddTaskComponent } from './pages/admin/admin-add-task/admin-add-task.component';
import { AdminLeaveApplyComponent } from './pages/admin/admin-leave-apply/admin-leave-apply.component';
import { CustomChatComponent } from './pages/admin/custom-chat/custom-chat.component';
import { ShowChatComponent } from './pages/admin/show-chat/show-chat.component';
import { ShowUserChatComponent } from './pages/user/show-user-chat/show-user-chat.component';
import { MessagesComponent } from './pages/admin/messages/messages.component';
import { ManagerDashboardComponent as ManagerDashboardComponent } from './pages/manager/manager-dashboard/manager-dashboard.component';
import { ManagerGuard } from './services/manager.guard';

import { UserAssignmentComponent as ManagerUserAssignmentComponent } from './pages/manager/user-assignment/user-assignment.component';
import { UserAssignComponent as ManagerUserAssignComponent} from './pages/manager/user-assign/user-assign.component';
import { UserLeaveRequestComponent as ManagerUserLeaveRequestComponent} from './pages/manager/user-leave-request/user-leave-request.component';
// import { UserDashboardComponent as ManagerUserDashboardComponent} from './pages/manager/manager-dashboard/manager-dashboard.component';
import { UserProfileComponent as ManagerUserProfileComponent } from './pages/manager/user-profile/user-profile.component';
import { UserSalaryComponent as ManagerUserSalaryComponent } from './pages/manager/user-salary/user-salary.component';
import { AttendanceComponent as ManagerAttendanceComponent} from './pages/manager/attendance/attendance.component';
import { AddTaskComponent  as ManagerAddTaskComponent} from './pages/manager/add-task/add-task.component';
import { TaskComponent as ManagerTaskComponent } from './pages/manager/task/task.component';
import { UserMeetingComponent as ManagerUserMeetingComponent } from './pages/manager/user-meeting/user-meeting.component';
import { OnuserEndLeaveComponent as ManagerOnuserEndLeaveComponent} from './pages/manager/onuser-end-leave/onuser-end-leave.component';
import { UpdateTaskComponent as ManagerUpdateTaskComponent } from './pages/manager/update-task/update-task.component';
import { UpdateAssignmentComponent as ManagerUpdateAssignmentComponent } from './pages/manager/update-assignment/update-assignment.component';
import { UserTotalSalaryComponent  as ManagerUserTotalSalaryComponent} from './pages/manager/user-total-salary/user-total-salary.component';
import { ForgetPasswordUserComponent as ManagerForgetPasswordUserComponent } from './pages/manager/forget-password-user/forget-password-user.component';
import { UserDocumentComponent as ManagerUserDocumentComponent} from './pages/manager/user-document/user-document.component';
import { GraphEventsComponent as ManagerGraphEventsComponent} from './pages/manager/graph-events/graph-events.component';
import { RatePerformanceComponent as ManagerRatePerformanceComponent} from './pages/manager/rate-performance/rate-performance.component';
import { ReviewComponent as ManagerReviewComponent} from './pages/manager/review/review.component';
import { DeveloperComponent  as ManagerDeveloperComponent} from './pages/manager/developer/developer.component';
import { UserWelcomeComponent  as ManagerUserWelcomeComponent} from './pages/manager/user-welcome/user-welcome.component';
import { FeedbackComponent as ManagerFeedbackComponent } from './pages/manager/feedback/feedback.component';
import { GoogleMapComponent as ManagerGoogleMapComponent } from './pages/manager/google-map/google-map.component';
import { MarkAttendanceComponent as ManagerMarkAttendanceComponent } from './pages/manager/mark-attendance/mark-attendance.component';
import { UserAnnouncementComponent  as ManagerUserAnnouncementComponent } from './pages/manager/user-announcement/user-announcement.component';
import { ShowUserChatComponent as ManagerShowUserChatComponent } from './pages/manager/show-user-chat/show-user-chat.component';
// import { SidebarComponent as ManagerSidebar } from './pages/manager/sidebar/sidebar.component';
import { AddMeetingComponent } from './pages/manager/add-meeting/add-meeting.component';
import { AddMessageComponent } from './pages/manager/add-message/add-message.component';
import { ApproveLeaveComponent } from './pages/manager/approve-leave/approve-leave.component';
import { LeaveApproveComponent } from './pages/manager/leave-approve/leave-approve.component';
import { HiringCandidateComponent } from './pages/admin/hiring-candidate/hiring-candidate.component';
import { CandidatesUpdateComponent } from './pages/admin/candidates-update/candidates-update.component';
import { ProjectsComponent } from './pages/admin/projects/projects.component';
import { ProjectDashboardComponent } from './pages/manager/project-dashboard/project-dashboard.component';
import { DetailsProjectComponent } from './pages/manager/details-project/details-project.component';
import { DetailAssignTaskComponent } from './pages/manager/detail-assign-task/detail-assign-task.component';
import { ManagerEmailComponent } from './pages/manager/manager-email/manager-email.component';
import { UserAssignmentDetailsComponent } from './pages/user/user-assignment-details/user-assignment-details.component';
import { SubCheckerComponent } from './pages/user/sub-checker/sub-checker.component';
import { AdminDetailsProjectComponent } from './pages/admin/admin-details-project/admin-details-project.component';
import { DeductionComponent } from './pages/admin/deduction/deduction.component';
import { ManagerHringComponent } from './pages/manager/manager-hring/manager-hring.component';

const routes: Routes = [
  { path: '',component: HomeComponent,pathMatch: 'full',},
  { path: 'calender', component: CalenderComponent, pathMatch: 'full',data: { hideSidebar: true }},
  { path: 'login',component: LoginComponent,pathMatch: 'full'},
  { path: 'forget-password', component: ForgetPasswordComponent, pathMatch: 'full',},
  // {path:'profile',component:ProfileComponent,pathMatch:'full'},
 
  
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [AdminGuard],
    children: [
      { path: '',component: WelcomeComponent},
      { path: 'admin-profile', component: ProfileComponent },
      { path: 'add-candidate', component: SaveCandidateComponent},
      { path: 'sales-attendance',component: SalesAttendanceComponent },
      { path: 'calendar-invite', component: CalendarInviteComponent},
      { path: 'showuser-task', component: ShowuserTaskComponent, pathMatch: 'full' },
      { path: 'show-employee-salary', component: ShowEmployeeSalaryComponent },
      { path: 'update-salary', component: UpdateSalaryComponent },
      // { path: 'employee-attendance', component: EmployeeAttendanceComponent },
      { path: 'all-employee-profile', component: AllEmployeeProfileComponent },
      { path: 'add-salary', component: AddSalaryComponent },
      { path: 'profile-update', component: UpdateUserProfileComponent },
      { path: 'showLeave-Request', component: ShowLeaveRequestComponent },
      { path: 'admin-document-show', component: AdminDocumentShowComponent },
      { path: 'HrAttendance', component: HRAttendanceComponent },
      { path: 'userGoogleMap/:id', component: UserGoogleMapComponentComponent },
      { path: 'userGoogleMap/:id/:str', component: UserGoogleMapComponentComponent },
      { path: 'single-employee-attendance', component: SingleEmployeeAttendanceComponent },
      { path: 'add-user-profile', component: AddUserProfileComponent },
      { path: 'add-comment', component: AddCommentComponent },
      { path: 'leaveapprove-dialog', component: DialogLeaveComponent },
      { path: 'show-meetings', component: ShowMeetingsComponent },
      { path: 'update-meetings', component: UpdateMeetingComponent },
      { path: 'all-candidate', component: AllCandidateComponent },
      { path: 'update-candidate', component: UpdateCandidateComponent },
      { path: 'user-task', component: UserTaskComponent },
      { path: 'previous-task', component: PreviousTaskComponent },
      { path: 'assign-task', component: AssignTaskComponent },
      // { path: 'assignment', component: AdminAssignmentComponent },
      // { path: 'admin-comment-assignment', component: AdminCommentAssignmentComponent },
      // { path: 'previous-assignment', component: PreviousAssignmentComponent, pathMatch: 'full' },
      { path: 'about-developer', component: AboutDeveloperComponent, pathMatch: 'full' },
      { path: 'single-assignment', component: SingleAssignmentComponent, pathMatch: 'full' },
      { path: 'event-chart', component: EventChartComponent, pathMatch: 'full' },
      { path: 'forget-password-admin', component: ForgetPasswordAdminComponent, pathMatch: 'full' },
      { path: 'rating', component: UserRatingComponent, pathMatch: 'full' },
      { path: 'show-user-ratings', component: ShowUserRatingsComponent, pathMatch: 'full' },
      { path: 'show-user-review', component: ShowUserReviewComponent, pathMatch: 'full' },
      { path: 'update-salary/:id', component: UpdateSalaryComponent },
      { path: 'show-all-approved-leave',component: ShowAllApprovedLeaveComponent},
      { path: 'meeting',component: MeetingsComponent, pathMatch: 'full'},
      { path: 'email', component: EmailComponent, pathMatch: 'full'},
      { path: 'new-announcement',component: NewAnnouncementComponent},
      { path: 'salary-cycle',component: SalaryCycleComponent},
      { path: 'add-task',component: AdminAddTaskComponent},
      { path: 'admin-leave-apply',component: AdminLeaveApplyComponent},
      { path: 'custom-chat',component:CustomChatComponent},
      { path: 'show-chat',component:ShowChatComponent},
      { path: 'message',component:MessagesComponent},
      {path:'hiring-candidate',component:HiringCandidateComponent},
      {path:'candidate-update',component:CandidatesUpdateComponent},
      {path:'project',component:ProjectsComponent},
      {path:'details-project',component:AdminDetailsProjectComponent},
      // {path:'deduction',component:DeductionComponent},
      // {path:'deduction',component:DeductionComponent},
      { path: 'deduction/:id', component: DeductionComponent }

    ],
  },
  {
    path: 'user-dashboard',
    component: UserDashboardComponent,
    canActivate: [NormalGuard],
    children: [
        
      { path: '',component: UserWelcomeComponent,pathMatch: 'full',},
      { path: 'home',component: HomeComponent,pathMatch: 'full',},
      { path: 'user-profile',component: UserProfileComponent,pathMatch: 'full'},
      { path:'user-assignment',component : UserAssignmentComponent,pathMatch:'full'},
      { path: 'attendance',component: AttendanceComponent,pathMatch: 'full'},
      { path: 'user-salary',component: UserSalaryComponent,pathMatch: 'full'},
      { path: 'developer',component:DeveloperComponent,pathMatch: 'full'},
      { path: 'onuser-end-leave', component: OnuserEndLeaveComponent, pathMatch: 'full'},
      { path: 'user-assign',component: UserAssignComponent,pathMatch: 'full'},
      { path: 'user-meeting',component: UserMeetingComponent, pathMatch: 'full'},
      { path: 'task',component: TaskComponent, pathMatch: 'full'},
      { path: 'update-task',component: UpdateTaskComponent,pathMatch: 'full'},
      { path: 'user-leave', component: UserLeaveRequestComponent,pathMatch: 'full'},
      { path: 'add-task', component: AddTaskComponent, pathMatch: 'full' },
      { path: 'forget-password-user', component: ForgetPasswordUserComponent,pathMatch: 'full' },
      { path: 'user-document', component: UserDocumentComponent, pathMatch: 'full' },
      { path: 'update-assignment', component: UpdateAssignmentComponent, pathMatch: 'full' },
      { path: 'user-review', component: ReviewComponent, pathMatch: 'full' },
      { path: 'feedback', component: FeedbackComponent, pathMatch: 'full' },
      { path: 'google-map', component: GoogleMapComponent, pathMatch: 'full' },
      { path: 'mark-attendance', component: MarkAttendanceComponent, pathMatch: 'full' },
      { path: 'show-user-chat', component: ShowUserChatComponent, pathMatch: 'full' },
      { path: 'user-assignment-details', component: UserAssignmentDetailsComponent, pathMatch: 'full' },
      { path: 'subtask-assignment-details', component: SubCheckerComponent, pathMatch: 'full' },
    ],
  },
  {
    path: 'manager-dashboard',
    component: ManagerDashboardComponent,
    canActivate: [ManagerGuard],
    children: [
        
      { path: '',component: ManagerUserWelcomeComponent,pathMatch: 'full'},
      // {path:'manager-dashboard',component:ManagerDashboardComponent,pathMatch:'full'},
      // { path: 'home',component:  ManagerHomeComponent,pathMatch: 'full'},
      { path: 'manager-profile',component:  ManagerUserProfileComponent,pathMatch: 'full'},
      { path:'user-assignment',component :  ManagerUserAssignmentComponent,pathMatch:'full'},
      { path: 'attendance',component:  ManagerAttendanceComponent,pathMatch: 'full'},
      { path: 'user-salary',component:  ManagerUserSalaryComponent,pathMatch: 'full'},
      { path: 'developer',component: ManagerDeveloperComponent,pathMatch: 'full'},
      { path: 'onuser-end-leave', component:  ManagerOnuserEndLeaveComponent, pathMatch: 'full'},
      { path: 'user-assign',component:  ManagerUserAssignComponent,pathMatch: 'full'},
      { path: 'user-meeting',component:  ManagerUserMeetingComponent, pathMatch: 'full'},
      { path: 'task',component:  ManagerTaskComponent, pathMatch: 'full'},
      { path: 'update-task',component:  ManagerUpdateTaskComponent,pathMatch: 'full'},
      { path: 'user-leave', component:  ManagerUserLeaveRequestComponent,pathMatch: 'full'},
      { path: 'add-task', component:  ManagerAddTaskComponent, pathMatch: 'full' },
      { path: 'forget-password-user', component:  ManagerForgetPasswordUserComponent,pathMatch: 'full' },
      { path: 'user-document', component:  ManagerUserDocumentComponent, pathMatch: 'full' },
      { path: 'update-assignment', component:  ManagerUpdateAssignmentComponent, pathMatch: 'full' },
      { path: 'user-review', component:  ManagerReviewComponent, pathMatch: 'full' },
      { path: 'feedback', component:  ManagerFeedbackComponent, pathMatch: 'full' },
      { path: 'google-map', component:  ManagerGoogleMapComponent, pathMatch: 'full' },
      { path: 'mark-attendances', component: ManagerMarkAttendanceComponent, pathMatch: 'full' },
      { path: 'show-user-chat', component:  ManagerShowUserChatComponent, pathMatch: 'full' },
      { path:'add-meeting',component:AddMeetingComponent,pathMatch:'full'},
      { path:'add-message',component:AddMessageComponent ,pathMatch:'full'},
      { path:'leaveapprove-dialog',component:LeaveApproveComponent ,pathMatch:'full'},
      { path:'approve-leave' ,component:ApproveLeaveComponent,pathMatch:'full'},
      {path:'email-manager',component:ManagerEmailComponent,pathMatch:'full'},
      {path:'project-dashboard',component:ProjectDashboardComponent,pathMatch:'full'},
      // {path:'details-project',component:DetailsProjectComponent,pathMatch:'full'},
      { path: 'details-project', component: DetailsProjectComponent,pathMatch:'full' },
      {path:'detail-assign',component:DetailAssignTaskComponent,pathMatch:'full'},
      {path:'hiring',component:ManagerHringComponent,pathMatch:'full'}

    ]
  },
  
  {
    canActivate: [NormalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
