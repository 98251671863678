<div class="container" style="margin-top: 15px;">
  <div class="row">
    <div class="col-md-8">
      <div class="graph-container">
        <div style="display: flex; justify-content: space-between;">
          <div class="inner">
            <h2 class="chart-title">Tasks Statistics</h2>
            <p class="chart-subtitle">Month-on-Month</p>
          </div>
        </div>
        <div class="chart-container">
          <ngx-charts-bar-vertical-2d
            [scheme]="colorScheme"
            [results]="barChartData"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [barPadding]="8"
            [roundEdges]="true"
            [animations]="true">
          </ngx-charts-bar-vertical-2d>
        </div>
      </div>
    </div>
    
    
    <div class="col-md-4">
      <div class="header">
        <h2>Top Performers</h2>
        <div class="dropdown">
          Last 7 Days 
        </div>
      </div>
      <div class="performers-list" *ngFor="let performer of performers | slice:0:5">
        <div class="performer"> 
          <span class="name">{{performer.userName}}</span>
          <span class="percentage">{{performer.overAllPerformance | number:'1.0-0'}}%</span>
          <div class="progress-bar">
            <div class="progress" [style.width.%]="performer.overAllPerformance"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
