<div class="salary-deduct">
<div class="salary-deduction-modal">
    <h3>Employee Salary Deduction</h3>
    <form (ngSubmit)="submitDeductionForm()">
      <label for="amount">Amount</label>
      <input type="number" id="deduction" [(ngModel)]="deduction.deduction" placeholder="Enter Amount">
      <label for="comment">Comment</label>
      <textarea id="commentsForSalary" [(ngModel)]="deduction.commentsForSalary" placeholder="Reason for Deduction"></textarea>
      <button type="submit" class="btn-update">Update Salary</button>
    </form>
  </div>
</div>
