import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css','/../../../shared/all-style/add-meeting-style.component.css']
})
export class AddTaskComponent implements OnInit {
  assignedById = localStorage.getItem('id');
  isSubmitting = false;
  employee: string;
  // deadLine: string;
  deadDate:String;
  deadTime:String;

  public user = {
    employee: '',
    date: '',
    time: '',
    subject: '',
    fileUrl: '',
    deadLine: '',
    deadDate:'',
    deadTime:'',
    assignedById: this.assignedById,
  };

  data: any;
  files: File[] = [];

  constructor(private snack: MatSnackBar, private mainService: MainService, private router: Router) {}

  ngOnInit(): void {
    this.mainService.managerAlllist(this.assignedById, true).subscribe((res: any) => {
      this.data = res;
    });
  }

  onSelect(event) {
    const maxFileSize = 300 * 1024; // 300 KB
    if (event.target.length > 1) {
      Swal.fire('Error', 'Only one file can be uploaded at a time', 'error');
      return;
    }
    const fileToAdd = event.target.files[0];
    if (fileToAdd.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
      return;
    }
    if (fileToAdd.type === 'application/pdf') {
      Swal.fire('Error', 'You cannot upload PDF files.', 'error');
      return;
    }
    this.files = [fileToAdd];
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  formSubmit() {
    if (this.files.length === 0 && !this.user.subject) {
      Swal.fire('Error', 'Please select at least one file or enter a task', 'error');
      return;
    }
     
    this.isSubmitting = true;
    
    // Validate that both date and time are filled
    if (!this.user.deadDate || !this.user.deadTime) {
      Swal.fire('Error', 'Please fill in both the deadline date and time.', 'error');
      this.isSubmitting = false;
      return;
    }
  
    // Merge date and time into a single timestamp (milliseconds since Unix epoch)
    const deadlineTimestamp = new Date(`${this.user.deadDate}T${this.user.deadTime}`).getTime();
  
    // Assign the timestamp to the deadLine field
    this.user.deadLine = deadlineTimestamp.toString();  // Convert timestamp to string if necessary for your backend
  
    // Proceed to upload files and add the task
    this.uploadFiles(deadlineTimestamp);
  }
  

  uploadFiles(deadlineTimestamp: number) {
    const documentsArray: string[] = [];
    let processedFiles = 0;

    if (this.files.length === 0) {
      this.addTask();
      return;
    }

    this.files.forEach((file) => {
      const folderName = 'taskDocs';
      const data = new FormData();
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (res) => {
          const secureUrl = res.message;
          documentsArray.push(secureUrl); 

          if (++processedFiles === this.files.length) {
            this.user.fileUrl = documentsArray[0];
            this.addTask();
          }
        },
        (error) => {
          Swal.fire('Error', 'File upload failed. Please try again.', 'error');
          this.isSubmitting = false;
        }
      );
    });
  }

  addTask() {
    const userId = this.employee || this.assignedById;
    this.mainService.addtask(userId, this.user).subscribe(
      (res) => {
        Swal.fire('Success', 'Task added successfully', 'success');
        this.router.navigate(['manager-dashboard/task']);
        this.isSubmitting = false;
      },
      (error) => {
        Swal.fire('Error', 'Failed to add task. Please try again.', 'error');
        this.isSubmitting = false;
      }
    );
  }
}
