<!-- <button mat-button [matMenuTriggerFor]="menu"><mat-icon mat-list-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/admin/update-salary', data.userId]"> <mat-icon mat-list-icon>update</mat-icon>Update</button>
            <button mat-menu-item [routerLink]="['/admin/update-salary', data.userId]"> <mat-icon mat-list-icon>update</mat-icon>Deduction</button>
          </mat-menu> -->
<h4> Update Employee Salary</h4>
<mat-card-content style="margin-top:40px">
  <div class="bootstrap-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-10 offset-md-1">

          <form *ngFor="let user of data" (submit)="updateUser()">
            <table class="table text-center">
              <tr>
                <td>Basic Salary</td>
                <td>
                  <input type="number" placeholder="Enter Basic Salary" name="basicSalary" [(ngModel)]="user.basicSalary" />
                </td>
              </tr>
              <tr>
                <td>House Rent Allowance</td>
                <td>
                  <input type="number" placeholder="Enter House Rent Allowance" name="hra" [(ngModel)]="user.hra" />
                </td>
              </tr>
              <tr>
                <td>Medical Fund</td>
                <td>
                  <input type="number" placeholder="Enter Medical Fund" name="medicalFund" [(ngModel)]="user.medicalFund" />
                </td>
              </tr>
              <tr>
                <td>Annual Bonus</td>
                <td>
                  <input type="number" placeholder="Enter Bonus" name="bonus" [(ngModel)]="user.bonus" />
                </td>
              </tr>
              <tr>
                <td>Convenience</td>
                <td>
                  <input type="number" placeholder="Enter Convenience" name="convience" [(ngModel)]="user.convience" />
                </td>
              </tr>
              <tr>
                <td>Incentive</td>
                <td>
                  <input type="number" placeholder="Enter Incentive" name="incentive" [(ngModel)]="user.incentive" />
                </td>
              </tr>
            </table>
            <div class="container text-center mt15">
              <button type="submit" mat-raised-button color="accent" style="margin-top: 30px;">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</mat-card-content>
          

<!-- backup -->
<!-- <h4> Update Salary Details <button mat-mini-fab color="accent" *ngIf="data"> User ID {{ userId }}</button></h4>
<form *ngFor="let data of data" (submit)="updateUser($event)" >
 <table>
   <div class="container">
     <div class="row">
       <div class="col-md-6">
         <label>hra:-</label>
         <input
           type="text"
           placeholder="HRA"
           id="hra"
           value="{{data.hra}}"
         /><br />
         <label>Medical Fund:-</label>
         <input
           type="text"
           placeholder="Medical Fund"
           id="medicalFund"
           value="{{data.medicalFund}}"
         /><br />
         <label>Convience:-</label>
         <input
           type="text"
           placeholder="Convience"
           id="convience"
           value="{{data.convience}}"
         /><br />

         <label>bonus:-</label>
         <input
           type="text"
           placeholder="Bonus"
           id="bonus"
           value="{{data.bonus}}"
         /><br />
         <label>Basic Salary:-</label>
         <input
           type="text"
           placeholder="Basic Salary"
           id="basicSalary"
           value="{{data.basicSalary}}"
         /><br />
         <label>Incentive:-</label>
         <input
           type="text"
           placeholder="Incentive"
           id="incentive"
           value="{{data.incentive}}"
         /><br />
     </div>
   </div>
   </div>
 </table>
 <button mat-raised-button color="accent" type="submit" style="margin-left: 300px;"> Update </button>
</form> -->