import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { HttpClient } from '@angular/common/http';
import { ElementRef, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit {
  userId: any;
  selectedOption: string = 'Total';
  yearlyTaskCount: number = 0;
  monthlyTaskCount: number = 0;
  showOptions: boolean = false;
  totalPendingTasks: number = 0;
  totalUserPresent: number = 0;
  todayMeetingCount: number = 0;
  interviewScheduled: number = 0;
  getPendingLeaves: number = 0;
  modalVisible: boolean = false;
  status:any;
  hostId:any;
  vacancyId:any;
  isActiveVacancy:any;
  hiringAIStatus:any;
  startOfDay:any;
  designation:any;
  @ViewChild('button') button: ElementRef;
  latestData: any= {};
  lineChartData = [
    {
      "name": "Marshall Islands",
      "series": [
        { "value": 3630, "name": "Jan" },
        { "value": 5291, "name": "Feb" },
        { "value": 5967, "name": "Mar" },
        { "value": 5024, "name": "Apr" },
        { "value": 3039, "name": "May" },
        { "value": 4549, "name": "Jun" },
        { "value": 4095, "name": "Jul" },
        { "value": 3026, "name": "Aug" },
        { "value": 5277, "name": "Sep" },
        { "value": 6128, "name": "Oct" },
        { "value": 2983, "name": "Nov" },
        { "value": 4586, "name": "Dec" }
      ]
    }
  ];
  
 
  showXAxis = true;
  showYAxis = true;
  showLegend = false;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = 'Months';
  yAxisLabel = 'Total Tasks';
  colorScheme = {
    domain: ['rgba(89, 50, 234, 1)', 'rgba(89, 50, 234, 1)', 'rgba(89, 50, 234, 1)', 'rgba(89, 50, 234, 1)']
  };

  constructor(private mainService: MainService, private http: HttpClient,private dialog: MatDialog) {}

  ngOnInit(): void {
    this.fetchTotalTaskCount();
    this.fetchTotalUserPresent();
    this.fecthtodayMeetingCount();
    this.GetPendingLeaves();
    this.mainService.getLatestMedia().subscribe(
      (res: any) => {
        this.latestData = res;
      },
      (error) => {
        console.error('Error fetching total task count', error);
        // Swal.fire('Error fetching total task count',error);
      }
    );

  }

  fetchTotalTaskCount() {
    this.mainService.totaltask().subscribe(
      (res: any) => {
        this.totalPendingTasks = res;
      },
      (error) => {
        console.error('Error fetching total task count', error);
        // Swal.fire('Error fetching total task count',error)
      }
    );
  }

  selectOption(option: string) {
    this.selectedOption = option;

    if (option === 'Yearly') {
      this.mainService.totaltask().subscribe(
        (res: any) => {
          this.yearlyTaskCount = res;
        },
        (error) => {
          console.error('Error fetching yearly task count', error);
          Swal.fire('Error fetching yearly task count',error)
        }
      );
    } else if (option === 'Monthly') {
      this.mainService.totaltaskmonthly().subscribe(
        (res: any) => {
          this.monthlyTaskCount = res;
        },
        (error) => {
          console.error('Error fetching monthly task count', error);
          Swal.fire('Error fetching monthly task count',error)
        }
      );
    } else {
      this.fetchTotalTaskCount();
    }
  }

  fetchTotalUserPresent() {
    this.mainService.totaluserPresent().subscribe(
      (res: any) => {
        this.totalUserPresent = res;
        // console.log("totaluserPresent"+JSON.stringify(res))
      },
      (error) => {
        console.error('Error fetching total user present count', error);
        Swal.fire('Error fetching total user present count',error)
      }
    );
  }


  fecthtodayMeetingCount(): void {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime().toString();
    const endOfDay = new Date(today.setHours(23, 59, 59, 999)).getTime().toString();
  
    this.mainService.showMeetings('SCHEDULED', this.userId, this.userId, startOfDay, endOfDay, 0, 500).subscribe(
      (res: any) => {
        this.todayMeetingCount = res.content.filter((meeting: any) => {
          const meetingTime = new Date(meeting.meetingTime).getTime();
          return meetingTime >= parseInt(startOfDay) && meetingTime <= parseInt(endOfDay);
        }).length;
        // console.log("testing"+this.todayMeetingCount)
      },
      error => {
        console.error('Error fetching meetings:', error);
        Swal.fire('Error fetching meetings:',error)
      }
    );
  }
  

  InterviewSchedule() {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime().toString();
    const endOfDay = new Date(today.setHours(23, 59, 59, 999)).getTime().toString();
    this.mainService.getAllhiring( this.userId,   this.vacancyId, this.isActiveVacancy,  this.hiringAIStatus, this.status, startOfDay,  this.designation,  endOfDay, 0, 100).subscribe(
      (res: any) => {
        this.interviewScheduled = res.content.filter((interview:any)=>{
          const interviewDate = new Date(interview.interviewDate).getTime();
          return interviewDate >= parseInt(startOfDay) && interviewDate <= parseInt(endOfDay);
        }).length;
        // console.log("countinterview"+this.interviewScheduled)
      },
      (error) => {
        console.error('Error fetching interview scheduled count', error);
        Swal.fire('Error fetching interview scheduled count',error);
      }
    );
  }

  GetPendingLeaves() {
    this.mainService.getPendingLeaveswelcome().subscribe(
      (res: any) => {
        this.getPendingLeaves = res;
      },
      (error) => {
        console.error('Error fetching pending leaves count', error);
        Swal.fire('Error fetching pending leaves count',error)
      }
    );
  }


  openModal() {
    this.modalVisible = true;
  }

}
