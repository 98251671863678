<div class="full-page"><h4>Your Leaves</h4>

<div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;margin-top:20px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
  <h5>Leaves List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
    <button mat-button class="custom-button" (click)="navigateToAddUserTask()">
      <mat-icon>add</mat-icon> ApplyLeave
    </button> 
  </div>
</div>

<div class="filter-form-wrapper" *ngIf="showFilterForm" style="position: absolute; top: 200px; right: 50px; background: white; padding: 20px; box-shadow: 0 0 10px #958c8c; border-radius: 8px; width: 340px;z-index: 1000;">
  <!-- Close icon -->

  <div style="display: flex; justify-content: space-between; align-items: center;">
    <h2 style="margin: 0;" style="color:black">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()" style="color: black;">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="onSubmitFilter()" style="display: block; margin-top: 20px;">
    <!-- Date range -->
    <div class="form-field" style="margin-bottom: 20px;">
      <label for="dateRange" style="color:black">Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style="width: 100%; margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
          style="width: 100%;"
        />
      </div>
    </div>

    <!-- Status -->
    <div class="form-field" style="margin-bottom: 20px;">
      <label for="status" style="color:black">Status:</label><br>
      <select id="status" name="status" [(ngModel)]="status" class="form-control" style="width: 100%;">
        <option value="">All</option>
        <option value="PENDING">Pending</option>
        <option value="APPROVED">Approved</option>
        <option value="REJECTED">Rejected</option>
      </select>
    </div>
    <!-- <div class="heelo">
      <select id="userId" name="employeeId" [(ngModel)]="user_id" style="width:100%">
        <option value="">Select Employee</option>
        <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.userName }} </option>
      </select>
    </div> -->
    
    <!-- Clear button -->
    <div style="display: flex; justify-content: space-between;">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
    </div>

  </form>

</div>
  <div class="container2" >
    <div class="scrollable-content">
    <table>
      <thead>
        <tr>
          <th>Apply Date</th>
          <th >Reason</th>
          <th style="width: 100px;">Leave Type</th>
          <th>Requested date</th>
          <th>Comments</th>
          <th>By</th>
          <th>Manager Status</th>
          <th>Status</th>
          
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of data">
          <td>{{ data.createdDate | date:'dd-MMM-yyyy a' }}</td>
          <td ><span (click)="openUserModal(data.reason,data.status,data.userName,data.leaveType)">{{ data.reason }}</span><span><a *ngIf="data.fileUrl" [href]="data.fileUrl" target="_blank"><img src="../../../../assets/task.png"  style="width: 20px;height:20px"/></a></span></td>
      
          <td>{{ getLeaveTypeText(data.leaveType) }}</td>
          <td><button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>event</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngFor="let leaveDate of data.leaveDates" style="color: black;">
              {{ leaveDate }}
            </button>
          </mat-menu>
          
          </td>
          <td>{{ data.comment }}</td>
          <td>{{data.approvedByName}}</td>
          <td>{{data.managerStatus}}</td>
          <td style="width: 112px;color:white;" [style.background-color]="
            data.status === 'PENDING'
              ? 'rgb(103, 59, 141)'
              : data.status === 'APPROVED'
              ? 'rgb(106, 106, 152)'
              : 'rgba(103, 59, 141, 0.67)'
          ">
            {{getpendingtype(data.status)}}
          </td>
          
        </tr>
      </tbody>
    </table>
    <div class="pagination-container">
      <div class="pagination-info">
        Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
      </div>
      <mat-paginator 
        [length]="totalElements" 
        [pageSize]="pageSize" 
        [pageSizeOptions]="[10, 20, 50, 100]" 
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>
    </div>
    

  </div>