  import { Component, Inject, OnInit } from '@angular/core';
  import { MatSnackBar } from '@angular/material/snack-bar';
  import Swal from 'sweetalert2';
  import { MainService } from 'src/app/MyService/main.service';
  import { ActivatedRoute, Router } from '@angular/router';
  import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

  @Component({
    selector: 'app-project-assign-modal',
    templateUrl: './project-assign-modal.component.html',
    styleUrls: ['./project-assign-modal.component.css']
  })
  export class ProjectAssignModalComponent implements OnInit {
    assignByID = localStorage.getItem('id');
    isSubmitting = false;
    userId: any;
    projectId: string;
    files: File[] = [];
    data: any;

    public taskData = {
      id: 0,
      assignToId: 0,
      assignById: this.assignByID,
      subtaskName: '',
      subtaskDescription: '',
      taskDeadline: 0, 
      fileUrl: '',
      subtaskStatus: 'ASSIGNED',
      priorityLevel: 'LOW',
      priority: true,
      story: ['']
    };

    constructor(
      private snack: MatSnackBar,
      private mainService: MainService,
      private route: ActivatedRoute,
      private router: Router,
      private dialogRef: MatDialogRef<ProjectAssignModalComponent>,
      @Inject(MAT_DIALOG_DATA) public dialogData: any  
    ) {
      this.projectId = dialogData.projectId;
    }

    ngOnInit(): void {
      
      // this.userId = this.route.snapshot.queryParams['id'];
      // this.taskData.assignToId = this.userId ?  Number(this.userId):0;
      this.userId = this.route.snapshot.queryParams['id'];
      console.log('User ID:', this.userId);  // Add this to verify the value
      this.taskData.assignToId = this.userId ? Number(this.userId) : 0;
if (this.taskData.assignToId === 0) {
  Swal.fire('Error', 'No user selected. Please select a user.', 'error');
  return;
}

      this.mainService.managerAlllist(this.assignByID, true).subscribe((res: any) => {
        this.data = res;
      });
    }

    onSelect(event: any) {
      const maxFileSize = 300 * 1024; // 300 KB
      const fileToAdd = event.addedFiles[0];

      if (event.addedFiles.length > 1) {
        Swal.fire('Error', 'Only one file can be uploaded at a time', 'error');
        return;
      }
      if (fileToAdd.size > maxFileSize) {
        Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
        return;
      }
      if (fileToAdd.type === 'application/pdf') {
        Swal.fire('Error', 'You cannot upload PDF files.', 'error');
        return;
      }
      this.files = [fileToAdd];
    }

    onRemove(event: any) {
      this.files.splice(this.files.indexOf(event), 1);
    }

    formSubmit() {
      if (this.taskData.taskDeadline) {
        const deadlineDate = new Date(this.taskData.taskDeadline);
        this.taskData.taskDeadline = deadlineDate.getTime(); 
      }

      if (!this.taskData.subtaskName && this.files.length === 0) {
        Swal.fire('Error', 'Please select at least one file or enter a task name', 'error');
        return;
      }

      this.isSubmitting = true;
      if (this.files.length > 0) {
        this.uploadFile();
      } else {
        this.saveTaskData();
      }
    }

    uploadFile() {
      const folderName = 'subtasksDocs'; // Adjust folder name as needed
      this.mainService.uploadFile(this.files[0], folderName).subscribe(
        (response: any) => {
          this.taskData.fileUrl = response.message; // Adjust based on your API's response
          this.saveTaskData();
        },
        (error) => {
          Swal.fire('Error', 'File upload failed', 'error');
          this.isSubmitting = false;
        }
      );
    }

    saveTaskData() {
      this.mainService.getSubtaskByAssignment(this.userId, this.taskData).subscribe(
        (response) => {
          Swal.fire({
            title: 'Success',
            text: 'Task saved successfully',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/manager-dashboard/details-project'], { queryParams: { id: this.projectId } });
              this.closeModal();
            }
          });
          this.isSubmitting = false;
        },
        (error) => {
          Swal.fire('Error', error, 'error');
          this.isSubmitting = false;
        }
      );
    }

    closeModal(): void {
      this.dialogRef.close();
    }
  }
