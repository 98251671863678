<section class="hero" style="background: radial-gradient(ellipse at center, #002447, #000);">
  <div class="hero-text">
    <h2>Save Time, Increase Productivity, and Manage Teams Effectively</h2>
    <p>Manage employee records, track performance, and simplify HR processes in one seamless platform. Gain insights into employee performance, engagement, and retention with real-time data and reports. Attract top talent with integrated applicant tracking, AI-powered screening, and simplified onboarding.</p>
    <a  [routerLink]="'/login'" class="example-icon" aria-label="Login">
    <button class="login-btn" *ngIf="!isLoggedIn">
      
        Login
     
    </button>
  </a>
  <div class="rating">
    <a href="https://play.google.com/store/apps/details?id=com.investmango.hrconsole&pcampaignid=web_share">
      <img src="../../../assets/playstore_svgrepo.com.png" alt="Play Store" class="rating-image">
    </a>
    <div class="rating-details">
      <a href="https://play.google.com/store/apps/details?id=com.investmango.hrconsole&pcampaignid=web_share" target="_blank">
      <div class="stars">
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star"></i>
        <i class="fa fa-star-half-alt"></i> 
     
      </div>
    </a>
      <span>4.4/5 (190 Reviews)</span>
    </div>
    <!-- <a href="https://play.google.com/store/apps/details?id=com.investmango.hrconsole&pcampaignid=web_share"  target="_blank" class="install-button">
      Install App
    </a> -->
  </div>
  
  
  </div>
  <div class="profiles">
    <div class="new-back" >
      <!-- style="background-image: url(../../../assets/Rectangle\ 7\ \(1\).png);" -->
      <div class="profile profile-left">
        <img src="../../../assets/photo1.avif" alt="Akshit Nayan">
        <div class="profile-info">
          <h4>Akshit Nayan</h4>
          <p>Software Engineer</p>
        </div>
      </div>
      <div class="profile profile-right">
        <img src="../../../assets/photo2.webp" alt="Nitya Niar">
        <div class="profile-info">
          <h4>Nitya Niar</h4>
          <p>HR Manager, Loovie</p>
        </div>
      </div>
    </div>
  </div>
  
</section>
