import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-manager-growth',
  templateUrl: './manager-growth.component.html',
  styleUrls: ['./manager-growth.component.css']
})
export class ManagerGrowthComponent implements OnInit ,OnChanges{
  @Input() userId: any;
user_id=localStorage.getItem('id')
  data: any;
  review: any = {};
  momData: any[] = [];
  barChartData: any[] = []; 
  month: any;

  showXAxis = true;
  showYAxis = true;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = 'Months';
  yAxisLabel = 'Score';
  animations = true;
  showGridLines = true;
  roundDomains = true;
  rotateXAxisTicks = true;
  referenceLines = true;
  useGradients = false;
  wrapTicks = true;
  
  colorScheme = {
    domain: ['rgba(89, 50, 234, 1)', 'rgba(234, 76, 76, 1)', 'rgba(76, 234, 133, 1)']
  };

  constructor(private mainService: MainService, private datePipe: DatePipe) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId'] && !changes['userId'].isFirstChange()) {
      console.log(`ngOnChanges - userId changed to: ${this.userId} at ${new Date().toLocaleString()}`);
      this.loadReviewData(); // Fetch data when userId changes
    }
  }

  
  ngOnInit(): void {
    this.loadReviewData();
  }

  loadReviewData() {
    const newId = this.userId|| this.user_id;
    this.mainService.performance(newId, this.month).subscribe(
      (res: any) => {
        this.review = res;
        this.momData = res.mom || []; 
        this.prepareChartData(); 
      },
      (error) => {
        console.error("Error fetching review data:", error); 
      }
    );
  }

  prepareChartData() {
    if (this.momData && this.momData.length > 0) {
      this.barChartData = [
        {
          "name": "Performance Score",
          "series": this.momData.map((item: any) => {
            const month = this.datePipe.transform(item.month, 'MMM yyyy');
            return {
              name: month,
              value: item.score
            };
          })
        }
      ];
    } else {
      this.barChartData = [];  // Empty state for the chart
    }
  }
}
