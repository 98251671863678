<mat-dialog-content>
  <div class="modal-overlay" (click)="closeModal()"></div>
  <div class="modal-content">
    <button class="close" (click)="closeModal()" aria-label="Close">
      &times;
    </button>

    <form (ngSubmit)="onformSubmit($event)" style="width: 100%;">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="user.status" id="status" name="status" required>
          <mat-option value="PENDING">PENDING</mat-option>
          <mat-option value="DONE">DONE</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%; margin-top: 16px;">
        <mat-label>Deadline Date</mat-label>
        <input matInput type="date" name="deadLineDate" [(ngModel)]="deadLineDate" required />
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%; margin-top: 16px;">
        <mat-label>Deadline Time</mat-label>
        <input matInput type="time" name="deadLineTime" [(ngModel)]="deadLineTime" required />
      </mat-form-field>

      <!-- Task textarea -->
      <mat-form-field appearance="outline" style="width: 100%; margin-top: 16px;">
        <mat-label>Task</mat-label>
        <textarea
          required
          matInput
          name="subject"
          placeholder="Enter task details here"
          [(ngModel)]="user.subject"
          rows="4"
        ></textarea>
      </mat-form-field>

      <!-- Submit button -->
      <div class="container text-center mt15">
        <button mat-raised-button color="accent">Update</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
