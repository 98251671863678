import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css', '/../../../shared/all-style/review-performance.component.css']
})
export class ReviewComponent implements OnInit {
  localUserId: string | null = localStorage.getItem('id'); // Local ID stored in localStorage
  selectedUserId: string = ''; // ID selected from the dropdown
  month: string = '';
  data: any = {};
  circleDasharray = 283;
  circleDashoffset: any;
  strokeColor = '#BDBDBD';
  performanceMessage = '';
  report: any = {};
  employees: any[] = [];
  timeline: any[] = [];

  constructor(private datePipe: DatePipe, private mainService: MainService) {}

  ngOnInit(): void {
    this.fetchEmployeeList();

    // Fetch performance and report data for the localUserId initially
    if (this.localUserId) {
      this.fetchPerformanceData(this.localUserId);
      this.fetchReportData(this.localUserId);
    } else {
      console.error('Local User ID is null or invalid');
    }
  }

  // Function to handle user change and fetch new data
  onUserChange(): void {
    console.log('User ID changed, fetching new data for user:', this.selectedUserId);

    // Fetch updated performance and report data for the selected userId
    this.fetchPerformanceData(this.selectedUserId);
    this.fetchReportData(this.selectedUserId);
  }

  // Fetch employee list based on localUserId
  fetchEmployeeList(): void {
    const localId = +this.localUserId!; // Convert localUserId to number if necessary
    console.log('Fetching employee list for local ID:', localId);

    this.mainService.managerAlllist(localId, true).subscribe(
      (res: any) => {
        this.employees = res;
        console.log('Employees:', this.employees);
      },
      (err: any) => {
        console.error('Error fetching employees:', err);
      }
    );
  }

  // Fetch performance data for the given userId
  fetchPerformanceData(userId: string): void {
    console.log('Fetching performance data for user:', userId, 'month:', this.month);

    this.mainService.performance(userId, this.month).subscribe(
      (res: any) => {
        this.data = res;
        console.log('Performance data:', this.data);
        this.updateTimelineAndPerformance();
      },
      (error: any) => {
        console.error('Error fetching performance data:', error);
      }
    );
  }

  // Fetch report data for the given userId
  fetchReportData(userId: string): void {
    this.mainService.report(userId).subscribe(
      (res: any) => {
        this.report = res;
        console.log('Report data for selected ID:', userId, this.report);
      },
      (error: any) => {
        console.error('Error fetching report data:', error);
      }
    );
  }

 

  // Update the timeline and performance UI based on fetched data
  updateTimelineAndPerformance(): void {
    if (this.data.timeline) {
      this.timeline = this.data.timeline;
    } else {
      console.warn('Timeline data is missing or invalid');
    }

    if (this.data.overAllPerformace !== undefined) {
      this.calculateCircleDashoffset();
      this.setPerformanceMessage();
    } else {
      console.warn('Overall performance data is missing or invalid');
    }
  }

  // Calculate the circle offset for performance visualization
  calculateCircleDashoffset(): void {
    const percentage = Math.round(this.data.overAllPerformace);
    const radius = 45;
    const circumference = 2 * Math.PI * radius;
    this.circleDasharray = circumference;
    this.circleDashoffset = circumference - (circumference * percentage / 100);
    this.strokeColor = percentage === 100 ? '#BDBDBD' : '#4FBAF0';
  }

  // Set the performance message based on overall performance
  setPerformanceMessage(): void {
    if (this.data.overAllPerformace < 50) {
      this.performanceMessage = 'Performance needs improvement!';
    } else if (this.data.overAllPerformace < 75) {
      this.performanceMessage = 'Good performance, keep it up!';
    } else if (this.data.overAllPerformace < 90) {
      this.performanceMessage = 'Great performance!';
    } else {
      this.performanceMessage = 'Outstanding performance!';
    }
  }

  // Function to get the default report value if none is selected
  getDefaultReportValue(metric: string): number {
    const defaultUserId = localStorage.getItem('id'); 
    // Fetch report for default user if necessary
    return this.report[0]?.[metric] || 0;
  }

  transform(metric: string): string {
    if (!metric) return metric;

    // Add spaces before each uppercase letter and capitalize the first letter
    const spacedText = metric.replace(/([a-z])([A-Z])/g, '$1 $2');
    
    // Capitalize the first letter of each word
    return spacedText.replace(/\b\w/g, char => char.toUpperCase());
  }
}
