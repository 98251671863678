<div class="full-page">
<h4> Request For Leave</h4>
<form (ngSubmit)="saveLeaveForm()" #leaveForm="ngForm">
  <div class="form-group">
    <label for="leaveType">Leave Type:</label>
    <mat-select
      id="leaveType"
      placeholder="Leave Type"
      name="leaveType"
      [(ngModel)]="leaveData.leaveType"
      required
      style=" border: 1px solid #cccccc;border-radius: 4px;padding: 10px;">
      <mat-option value="ABSENT" >Absent</mat-option>
      <mat-option value="HALF_DAY" >Half Day</mat-option>
      <mat-option value="LATE" >Late</mat-option>
      <mat-option value="WFH" >Work From Home</mat-option>
    </mat-select>
  </div>
  <div class="form-group">
    <label>Upload Documents</label>
    <input
      type="file"
      name="fileUrl"
      id="fileUrl"
      (change)="onSelect($event)"
    />
  </div>
  <!-- <div class="form-group">
    <label for="leaveDates">Dates:</label>
    <input type="date" id="leaveDates" name="leaveDates" [(ngModel)]="selectedDate" (ngModelChange)="addDate()">
  </div>
  
  <div *ngIf="leaveData.leaveDates.length > 0">
    <label>Selected Dates:</label>
    <ul style="border: 1px solid #cccccc; height: auto; background: #ce3262; padding: 10px;">
      <li style="color: white; height: auto; border-radius: 6px; background: black; border: 6px solid #ce3262; margin-top: 5px;" *ngFor="let date of leaveData.leaveDates">
        <span style="cursor: pointer;">{{ date }}</span>
        <button style="background: none; border: none; color: white; cursor: pointer; margin-top: -26px; margin-left: 10px;" (click)="removeDate(date)">X</button>
         <br *ngIf="(i + 1) % 3 === 0 && i !== leaveData.leaveDates.length - 1">
      </li>
    </ul>
   
  </div> -->
  
  <mat-form-field>
    <mat-label>Choose dates</mat-label>
    <input style="width: 96%;"
      matInput
      ngxDaterangepickerMd
      [(ngModel)]="selectedRange"
      (ngModelChange)="onDateRangeChange($event)"
      required
    />
  </mat-form-field>
  
  <div class="form-group">
    <label for="reason">Reason:</label>
    <textarea
      id="reason"
      name="reason"
      [(ngModel)]="leaveData.reason"
      required
      class="form-control"
    ></textarea>
  </div>

  <button  mat-raised-button color="accent" type="submit" [disabled]="!leaveForm.valid">Save</button>
</form>
</div>