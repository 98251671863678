<div class="full-page">
<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header" style="font-size: 24px;">
          Projects
        </div>
        <div class="card-body" style="overflow-y: scroll; height: 70vh;width: 100%;">
          <div class="row" *ngIf="projectdetails?.length > 0" >
            <div class="col-md-12">
              <div class="card" *ngFor="let projectdetail of projectdetails">
                <div class="card-header" style="justify-content: space-between;display: flex;" >
                  {{ projectdetail.subject }}
                 <span >  {{ projectdetail.createdDate | date: 'dd-MMM-yy' }}</span>


                 
                </div>
                <div class="card-body" style="justify-content: space-between;display: flex;" >
                 <p>{{projectdetail.description}}</p>
                 <button class="btn btn-primary" 
                 [routerLink]="['/user-dashboard/user-assignment-details']" 
                 [queryParams]="{ id: projectdetail.id }">
           More Details
         </button>
                </div>
              </div>
            </div>
        
            <div *ngIf="projectdetails?.length === 0">
              No projects found.
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
</div>