import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
import { UserTotalSalaryComponent } from 'src/app/pages/user/user-total-salary/user-total-salary.component';
import Swal from 'sweetalert2';

import { saveAs } from 'file-saver';

@Component({
  selector: 'app-user-salary',
  templateUrl: './user-salary.component.html',
  styleUrls: ['./user-salary.component.css']
})
export class UserSalaryComponent implements OnInit {
  dialogRef: any;
  datas: any;
  constructor(private http: HttpClient, private mainService: MainService, private dialog: MatDialog) { }

  id = localStorage.getItem('id');
  phone = localStorage.getItem('phone');

  ngOnInit(): void {
    this.mainService.showuserSalary(this.id).subscribe(res => {
      this.datas = res;
    });
    


  }


  openDialog(userId: any) {
    this.dialogRef = this.dialog.open(UserTotalSalaryComponent, {
      data: userId
    });
  }


  downloadpdf() {
    this.mainService.downloadsalarypdf(this.id).subscribe((blobData: Blob) => {
      const blob = new Blob([blobData], { type: 'application/pdf' });

      saveAs(blob, 'salary.pdf');

      Swal.fire({
        title: 'Downloaded',
        text: 'The PDF has been downloaded successfully',
        icon: 'success',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      });
      (error) => {
        console.error('Pdf or Excel Not Downloaded: ', error);
      };
    });
  }
}
