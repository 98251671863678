<h4>Request For Leave</h4>
<form (ngSubmit)="saveLeaveForm()" #leaveForm="ngForm">
  <div class="form-group">
    <label for="leaveType">Leave Type:</label>
    <mat-select
      id="leaveType"
      placeholder="Leave Type"
      name="leaveType"
      [(ngModel)]="leaveData.leaveType"
      required
      style="border: 1px solid #cccccc; border-radius: 4px; padding: 10px;"
    >
      <mat-option value="ABSENT">Absent</mat-option>
      <mat-option value="HALF_DAY">Half Day</mat-option>
      <mat-option value="LATE">Late</mat-option>
      <mat-option value="WFH">Work From Home</mat-option>
    </mat-select>
  </div>

  <div class="form-group">
    <label>Upload Documents</label>
    <input
      type="file"
      name="fileUrl"
      id="fileUrl"
      (change)="onSelect($event)"
    />
  </div>

  <mat-form-field>
    <mat-label>Choose dates</mat-label>
    <input style="width: 96%;"
      matInput
      ngxDaterangepickerMd
      [(ngModel)]="selectedRange"
      (ngModelChange)="onDateRangeChange($event)"
      required
    />
  </mat-form-field>

  <div class="form-group">
    <label for="reason">Reason:</label>
    <textarea
      id="reason"
      name="reason"
      [(ngModel)]="leaveData.reason"
      required
      class="form-control"
      style="border: 1px solid #cccccc; border-radius: 4px; padding: 10px;"
    ></textarea>
  </div>

  <button
    mat-raised-button
    color="accent"
    type="submit"
    [disabled]="!leaveForm.valid"
  >
    Save
  </button>
</form>
