<div class="full-page"><h4> Add Task</h4>
  <mat-card-content>
    <div class="meetingContainer">
          <form (ngSubmit)="formSubmit()">
            <div class="row" style=" margin-top:10px;">
              <div class="col">
                <label>DeadLine Date</label>
                <input type="date" id="deadlineDate" name="deadlineDate" class="form-control"  [(ngModel)]="user.deadDate"  >
              </div>
              <div class="col">
                <label>DeadLine Time</label>
                <input type="time" id="deadlineTime" name="deadlineTime" class="form-control"  [(ngModel)]="user.deadTime" >
              </div>
            </div>
            <div>
              <label>Upload File</label>
              <input type="file" name="taskFiles" (change)="onSelect($event)" />
              <!-- <div *ngIf="selectedFile">
                <span>{{ selectedFile.name }} ({{ selectedFile.type }})</span>
              </div> -->
            </div>
            <!-- <mat-form-field class="w100" appearance="outline" style="margin-top: 34px; width: 100%;">
              <mat-label style="color: white;">Task</mat-label>
              <textarea required autofocus type="text" name="Task" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="color: white; background: #ce32622e; width: 100%;" rows="14"></textarea>
            </mat-form-field> -->
            <!-- <mat-form-field class="w100" appearance="outline"  style=" margin-top:10px;" >
              <mat-label style="color: white;">Task</mat-label>
              <textarea required autofocus type="text" name="Task" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="color: white;" rows="8"></textarea>
            </mat-form-field> -->
            <textarea type="text" name="Task" placeholder="Enter here" [(ngModel)]="user.subject" style="color: white;margin-top:20px" rows="8">
 
            </textarea>
            
            <mat-spinner *ngIf="isSubmitting" diameter="30"></mat-spinner>
            <div class="container text-center mt15">
              <button mat-raised-button color="accent">Add</button>
            </div>
          </form>
        </div>
  </mat-card-content>


</div>