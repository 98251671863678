<div class="col-md-12 container2" style="width: 100%;position: relative; height:100vh">
  <div class="container">
    <div class="top-header">
    <div class="header">
      <h1>My Activity Stats</h1>
     
    </div>
    <div class="stats-container">
      <div class="stat">
        <h2>Avg. Punch in</h2>
        <h1>{{perform.averagePunchInTime}} </h1>
      </div>
      <div class="stat">
        <h2>Task Growth</h2>
        <h1><span style="color:#50C878;height: 2px;">↑</span>{{ isNaN(perform.taskCompletionRatio) ? 0 : perform.taskCompletionRatio | number:'1.2-2' }}%</h1>
      </div>
      <div class="stat">
        <h2>Tasks Done</h2>
        <h1>{{perform.completedTasks}}</h1>
        
      </div>
      <!-- <div class=""> -->
        <div class="action" style="margin-left: 30px;">
          <span>Admin Review</span>
          <p style="   width:500px; background: #343333;
          height: 68px;
          padding: 10px;
          border-radius: 20px;
          overflow-y: auto;">{{perform.review}}</div>
        <!-- <div class="action">
          <i class="fa fa-circle" style="color: #FDD835;"></i>
          <span>Manager review</span>
        </div> -->
      <!-- </div> -->
    </div>
  </div>
  </div>
    <!-- <app-task-statistics></app-task-statistics> -->
    <div class="row" style="margin-top: 20px; margin-left:0px;height:200px;width: 100%;">
      <div class="col-md-12" >
        <app-employee-task></app-employee-task>
      </div>
      <div class="col-md-12" style="height:100vh">
        <app-employee-status ></app-employee-status>
      </div>
      
    </div>
  
</div>