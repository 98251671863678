<div class="container">
<h4> Employee's Salary</h4>
<div class="dropdown" [matMenuTriggerFor]="downloadMenu" style="margin-left: 10px;">
  <button mat-mini-fab>
      <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #downloadMenu="matMenu">
      <button mat-menu-item (click)="downloadpdf()"> <mat-icon>file_download</mat-icon> Download as PDF</button>
      <button mat-menu-item (click)="redirectToSalaryCycle()"> <mat-icon>attach_money</mat-icon> Salary Cycle</button>
  </mat-menu>
</div>

  <div class="container2" style="margin-top: 39px;">
    <table>
      <thead>
        <tr>
          <th style="width: 72px">Id</th>
          <th>Name</th>
          <th>Basicsalary</th>
          <th>Hra</th>
          <th>Medicalfund</th>
          <th>Bonus</th>
          <th>Convience</th>
          <th>Incentive</th>
          <th>Deduction</th>
          <th>Totalsalary</th>
          <th>Pay Salary</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of salary">
          <td>{{ data.userId }}</td>
          <td>{{ data.userName }}</td>
          <td>{{ data.basicSalary }}</td>
          <td>{{ data.hra }}</td>
          <td>{{ data.medicalFund }}</td>
          <td>{{ data.bonus }}</td>
          <td>{{ data.convience }}</td>
          <td>{{ data.incentive }}</td>
          <td>{{ data.deduction}}</td>
          <td>{{ data.totalsalary }}</td>
          <td style="background: #6a6a98; color: white">
            {{ data.monthlysalary }}
          </td>
          <button mat-button [matMenuTriggerFor]="menu"><mat-icon mat-list-icon>more_vert</mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/admin/update-salary', data.userId]"> <mat-icon mat-list-icon>update</mat-icon>Update</button>
            <button mat-menu-item [routerLink]="['/admin/deduction', data.userId]"> <mat-icon mat-list-icon>update</mat-icon>Deduction</button>
            <button mat-menu-item (click)="openDialog(data.userId)">  <mat-icon mat-list-icon>attach_money</mat-icon>Total Salary</button>
            <button mat-menu-item (click)="ondelete(data.userId)">  <mat-icon  mat-list-icon>delete</mat-icon>Delete</button>
          </mat-menu>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
    </div>
    
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>

</div>