<!-- <mat-dialog-content> -->
  <div (click)="closeModal()"></div>
  <!-- <div class="modal-content"> -->
   
    <h1>Update Task</h1>
    <span class="close" (click)="closeModal()">&times;</span>
    <form (ngSubmit)="onFormSubmit($event)">
      <mat-form-field class="w100" appearance="outline" >
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="user.status" id="status" name="status" style="background:white;">
          <mat-option value="PENDING">PENDING</mat-option>
          <mat-option value="DONE">DONE</mat-option>
        </mat-select>
      </mat-form-field>
      <div style="display: flex; gap: 20px; align-items: center; margin-top: 10px;">
        <!-- Deadline Date -->
        <div class="start">
          <label for="deadLineDate">Deadline Date</label><br>
          <input type="date" id="deadLineDate" name="deadLineDate" [(ngModel)]="deadLineDate"  required />
        </div>
      
        <!-- Deadline Time -->
        <div class="start">
          <label for="deadLineTime">Deadline Time</label><br>
          <input type="time" id="deadLineTime" name="deadLineTime" [(ngModel)]="deadLineTime"  required />
        </div>
      </div>
      
      <mat-form-field class="w100" appearance="outline" style="margin-top: 10px; width: 100%;">
        <mat-label>Comment</mat-label>
        <textarea required autofocus name="comment" matInput placeholder="Enter here" [(ngModel)]="user.comments" style="background:white; width: 100%;" rows="4"></textarea>
      </mat-form-field>
      <mat-form-field class="w100" appearance="outline" style="margin-top: 10px; width: 100%;">
        <mat-label>Task</mat-label>
        <textarea required autofocus name="subject" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="background:white; width: 100%;" rows="4"></textarea>
      </mat-form-field>

      <div class="container text-center mt15">
        <button mat-raised-button color="accent">Update</button>
      </div>
    </form>
  <!-- </div> -->
<!-- </mat-dialog-content> -->
