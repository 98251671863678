
<div class="container2" style="height:100vh">
  <div class="container">
  <h2 style="color: white;margin-top: 10px;">Assigment ><span>Task & Projects</span></h2><br>
</div>
<div class="container" >
<div class="card">
  <div class="card-header">
    <h3>Task Growth</h3>
  </div>
  <div class="card-content">
      <img src="../../../../assets/arrow (2).png" alt="Arrow Up"  >
    {{assignmentRes.totalSubtasksCount}}
  </div>
</div>
<div class="card">
  <div class="card-header">
    <h3>Pending Task</h3>
   
  </div>
  <div class="card-content">
    {{assignmentRes.pendingSubtasksCount}}
    <img src="../../../../assets/second-card.png" alt="Refresh"  style="height: 30px;">
  </div>
</div>
<div class="card">
  <div class="card-header">
    <h3>Completed projects</h3>
   
  </div>
  <div class="card-content">
    {{assignmentRes.doneAssignmentCount}} 
    <img src="../../../../assets/clock.png" alt="Resize Vertical" style="height: 30px;">
  </div>
</div>
<div class="calendar">
  <div class="calendar-header">
    <button (click)="prevWeek()">&lt;</button>
    <h3>{{ monthNames[currentMonth] }} {{ currentYear }}</h3>
    <button (click)="nextWeek()">&gt;</button>
  </div>
  <div class="calendar-body">
    <div class="calendar-day-name" *ngFor="let dayName of weekDayNames">
      {{ dayName }}
    </div>
    <div class="calendar-day" 
         *ngFor="let day of visibleWeekDays" 
         [class.today]="isToday(day)" 
         (click)="selectDay(day)">
      {{ day.getDate() }}
    </div>
  </div>
</div>
</div>
<div class="task-list-header container" style="padding: 8px; ">
  <h3>Current Task</h3>
</div>
<div class="container">
  <div class="task-list" style="width: 100%;">
    <div class="task-list-item" style="display: flow; color: white; font-size: 12px;">
      <h2 style="color:white">
        <img src="../../../../assets/Board Icon.png" alt="task"/> <span>New Tasks</span>
      </h2>
    </div>
    
    <div class="task-list-item" *ngFor="let task of recentSubTasks" style="display:flex;justify-content: space-between;">
      <span style="width: 100%;display: flex;">{{ task.name }}
        <div *ngIf="task.fileUrl" >
          <a href="{{ task.fileUrl }}" target="_blank" style="color: blue; text-decoration: underline;">
            <img src="../../../../assets/attach-file.png" alt="Assignment files">
          </a>
        </div>
      </span>
      <span style="float: right;">{{ task.deadline | date:'dd MMMM yyyy' }}</span>
    </div>
  </div>
</div>

<div class="container" style="    display: flex;
padding: 6px;
gap: 10px;
margin-top: 20px;
color: white;">
<h1>Projects</h1>
</div>
<div class="slick-carousel">
  <div *ngFor="let assignmentItem of assignment" class="project" 
    [routerLink]="['/admin/details-project/']" 
    [queryParams]="{ id: assignmentItem.id }" style="    width: 360px;
    padding: 20px;
    border: 2px solid #312f2f;
    border-radius: 12px;">
    <img [src]="assignmentItem.banner || '../../../../assets/project-organization.png'" alt="Image"  />
    <div class="project-details">
      <h3>{{assignmentItem.subject}}</h3>
      <p>{{assignmentItem.headings}}</p>
      <div class="progress-bar">
        <div class="progress" [style.width]="assignmentItem.progress + '%'"></div>
      </div>
      <div class="project-info">
        <div class="project-time">
          {{formatTimestampToDate(assignmentItem.deadLine)}}  
        </div>
      </div>
    </div>
  </div>
</div>
</div>



