<h2 mat-dialog-title style="    text-align: center;margin-top: 10px;">Send Email</h2>

<mat-dialog-content>
  <span class="close" (click)="closeEmailPopup()">&times;</span>
  <div class="email-form-container">
    <form (ngSubmit)="sendEmail()" class="email-form">
      <!-- To Section -->
      <div class="form-group">
        <!-- <label for="toList">To</label> -->
        <!-- <select 
          id="toList" 
          [(ngModel)]="emailDetails.toList" 
          name="toList" 
          class="form-control white-text" 
          required
        >
          <option value="" disabled selected>Select Employee</option>
          <option *ngFor="let employee of user" [value]="employee.userEmail">
            {{ employee.userName }} 
          </option>
        </select> -->
        <label>To</label>
        <mat-form-field class="white-text email-list" appearance="outline"  >
          <!-- <mat-label class="white-text">To</mat-label> -->
          <mat-select [formControl]="toControl" (selectionChange)="onToSelectionChange($event)" multiple>
            <mat-option (click)="toggleSelectAllTO()">
              {{ toAllSelected ? 'Unselect All' : 'Select All' }}
            </mat-option>
            <mat-option *ngFor="let employee of user" [value]="employee.userEmail">
              Name: {{ employee.userName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- CC Section -->
       <div class="chekcing" style="display:flex;" >
      <div class="form-group col-md-6">
        <label for="ccList">CC</label>
        <!-- <button type="button" (click)="toggleCcInput()" class="toggle-btn">{{ showCcInput ? 'Hide CC' : 'Show CC' }}</button> -->
        <div>
          <mat-form-field class="white-text email-list" appearance="outline"  >
            <mat-label class="white-text">Select Employees</mat-label>
            <mat-select [formControl]="ccControl" (selectionChange)="onCcSelectionChange($event)" multiple>
              <mat-option (click)="toggleSelectAllCc()">
                {{ ccAllSelected ? 'Unselect All' : 'Select All' }}
              </mat-option>
              <mat-option *ngFor="let employee of user" [value]="employee.userEmail">
                Name: {{ employee.userName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- BCC Section -->
      <div class="form-group col-md-6">
        <label for="bccList">BCC</label>
        <!-- <button type="button" (click)="toggleBccInput()" class="toggle-btn">{{ showBccInput ? 'Hide BCC' : 'Show BCC' }}</button> -->
        <div >
          <mat-form-field class="white-text  email-list" appearance="outline">
            <mat-label class="white-text">Select Employees</mat-label>
            <mat-select [formControl]="bccControl" (selectionChange)="onBccSelectionChange($event)" multiple>
              <mat-option (click)="toggleSelectAllBcc()">
                {{ bccAllSelected ? 'Unselect All' : 'Select All' }}
              </mat-option>
              <mat-option *ngFor="let employee of user" [value]="employee.userEmail">
                Name: {{ employee.userName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
      <!-- Subject Section -->
      <div class="form-group">
        <label for="subject">Subject</label>
        <input
          type="text"
          id="subject"
          [(ngModel)]="emailDetails.subject"
          name="subject"
          placeholder="Subject"
          required
          class="white-text"
          style="padding: 12px;"
        />
      </div>
      
      <!-- Body Section -->
      <label for="body">Body</label>
      <div class="form-group">
        <angular-editor
          id="body"
          [(ngModel)]="emailDetails.body"
          name="body"
          [config]="editorConfig"
          required
        ></angular-editor>
      </div>

      <!-- Attachments Section -->
      <div class="form-group">
        <label for="attachmentList">Attachments</label>
        <input
          id="attachmentList"
          type="file"
          (change)="onFileSelected($event)"
          multiple
        />
        <div class="attachment-list">
          <div *ngFor="let file of emailDetails.attachmentList; let i = index">
            <span>{{ file.name }}</span>
            <button type="button" (click)="removeAttachment(i)">×</button>
          </div>
        </div>
      </div>

      <button type="submit" class="submit-btn">Send Email</button>
    </form>
  </div>
</mat-dialog-content>
