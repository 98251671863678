
<div style="height:100vh"><h4 class="user-details" style="margin-top: 10px;">
  <span class="user-id">User ID: {{ userId }}</span><br>
  <h2 class="user-name" style="text-transform: uppercase;">
    {{ userName }}
    <span *ngIf="user.documentVerified" class="green-tick" style="margin-top: 30px;">
      <img src="../../../../assets/Green-Tick.png" alt="verification" style="width: 13%;">
    </span>
  </h2>
</h4>

<button mat-fab style="margin-bottom: 10px;" (click)="openDialog(userId)">
  <i class="fas fa-check-circle"></i> Verified
</button>

<div class="container">
  <div class="box" *ngFor="let document of user.urls">
    <div class="card-header">{{ document.category }}</div>
    <button mat-icon-button [matMenuTriggerFor]="menu" style="float:right" (click)="downloadImage(document.imageUrl, getImageAlt(document.imageUrl))">
      <mat-icon class="more-icon">download</mat-icon>
    </button>
    <div class="card card-body">
      <a [href]="document.imageUrl" target="_blank" style="color: black">
        <img class="card-image" [src]="document.imageUrl" (click)="openImagePopup(document.imageUrl)" [alt]="getImageAlt(document.imageUrl)" />
      </a>
    </div>
  </div>
</div>

</div>