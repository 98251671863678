<div class="full-page"><h4>Messages</h4>
<div class="meetingContainer">
  <form [formGroup]="form" (ngSubmit)="formSubmit($event)">
    <div>
      <label for="subject">Purpose </label>
      <input type="text" id="purpose" formControlName="purpose" required >
    </div>

    <label for="message">Select Employee:</label>
    <mat-form-field class="white-text w100" appearance="outline">
      <mat-label class="white-text">Select Employees</mat-label>
      <mat-select [formControl]="selectedEmployeesControl" multiple (selectionChange)="onSelectionChange($event)">
        <mat-option (click)="toggleSelectAll($event)">
          {{ allSelected ? 'Unselect All' : 'Select All' }}
        </mat-option>
        <mat-option *ngFor="let employee of data" [value]="employee.id">
          ID: {{ employee.id }} - Name: {{ employee.userName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <label for="message">Upload File:</label>
      <input id="file" type="file" (change)="onSelect($event)" />
    </div>

    <div>
      <label for="message">Message:</label>
      <textarea id="message" formControlName="message" ></textarea>
    </div>

    <button type="submit" class="submit-button">Submit</button>
  </form>
</div>
</div>