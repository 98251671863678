import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-new-announcement',
  templateUrl: './new-announcement.component.html',
  styleUrls: ['./new-announcement.component.css', '/../../../shared/all-style/add-leave-style.component.css']
})
export class NewAnnouncementComponent implements OnInit {
  form: FormGroup;
  isSubmitting = false;
  file: File | null = null;

  constructor(
    private fb: FormBuilder,
    private mainService: MainService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      eventDate: ['', Validators.required],
      eventTime: ['', Validators.required]
    });
  }

  onSelect(event) {
    // console.log(event);
    const maxFileSize = 300 * 1024;
    const fileToAdd = event.addedFiles[0];
    if (fileToAdd.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 300 KB limit. Please upload a file smaller than 300 KB.', 'error');
      return;
    }
    if (fileToAdd.type === 'application/pdf') {
      Swal.fire('Error', 'You cannot upload PDF files.', 'error');
      return;
    }
    this.file = fileToAdd;
  }

  onRemove(event) {
    // console.log(event);
    this.file = null;
  }

  formSubmit() {
    this.isSubmitting = true;
    this.uploadFile();
  }

  uploadFile() {
    const folderName = 'announcements';
    const data = new FormData();
    data.append('file', this.file as Blob);
    this.mainService.uploadFile(this.file,folderName).subscribe(
      (res) => {
        // console.log(res);
        const secureUrl = res.message;
        // console.log('Uploaded file URL:', secureUrl);
        const announcementData = {
          subject: this.form.get('subject').value,
          description: this.form.get('description').value,
          eventDateTime: new Date(this.form.get('eventDate').value + 'T' + this.form.get('eventTime').value).getTime(),
          poster: secureUrl
        };
        this.saveAnnouncement(announcementData);
      },
      (error) => {
        // console.error('Upload error:', error);
        Swal.fire('Error', 'Failed to upload file to Cloudinary', 'error');
        this.isSubmitting = false; 
      }
    );
  }

  saveAnnouncement(announcementData) {
    // Assuming you have a method in MainService to save announcement
    this.mainService.announcement(announcementData).subscribe(
      (response) => {
        // console.log('API response:', response);
        Swal.fire('Success', 'Announcement saved successfully', 'success');
        this.isSubmitting = false;
        // Reset form and file
        this.form.reset();
        this.file = null;
      },
      (error) => {
        // console.error('API error:', error);
        Swal.fire('Error', 'Failed to save announcement', 'error');
        this.isSubmitting = false;
      }
    );
  }
}
