<div class="container2">
  <div class="team-management-container">
    <div class="header" >
      <h3>Team Management</h3>
      <p>Analyze your team stats</p>
      <a routerLink="./all-employee-profile"><button class="see-all-btn">See Employee Details</button> </a>
    </div>
    <div class="team-list" *ngFor="let user of data">
      <div class="team-member">
        <div class="info" style="display: flex;gap: 10px  ;">
          <span class="name"><img class="myImage" style="height: 30px; width: 30px; border-radius: 50%;" [src]="user.profile" alt="Profile Picture" *ngIf="user && user.profile"/></span>
          <div class="name">{{user.Name }}</div>
        </div>
        <div class="achievement">
          <span class="score" [ngClass]="{'green': user.active, 'red': !user.active}">
            <span class="status-dot" [ngClass]="{'active-dot': user.active, 'inactive-dot': !user.active}"></span>
            {{ user.active ? 'Online' : 'Offline' }}
          </span>
        </div>
        
        <div class="time">{{ user.date | date: 'dd-MMM-yyyy ' }}</div>
        <div class="average-time">Average ~ <br> {{ user.Performance | replaceUnderscore }} </div>
        <div class="project-status">{{ formatDateWithAbbreviatedMonth(user.lastLogin) }} <br>Last Login</div>
      </div>
  
    </div>
  </div>
</div>

