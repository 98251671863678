import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MainService } from "src/app/MyService/main.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-update-task',
  templateUrl: './update-task.component.html',
  styleUrls: ['./update-task.component.css']
})
export class UpdateTaskComponent implements OnInit {
  userId = localStorage.getItem('id');
  data: any;
  taskId: string;
  public user = {
    status: '',
    subject: '',
    comments:'',
    deadLine: null,
  };

  public deadLineDate: string; 
  public deadLineTime: string; 

  constructor(
    private mainService: MainService,
    private router: Router,
    private dialogRef: MatDialogRef<UpdateTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    this.taskId = this.dialogData.taskId;
    this.user.subject = this.dialogData.subject;
    this.user.comments=this.dialogData.comments;

    if (this.dialogData.deadLine) {
      const deadline = new Date(this.dialogData.deadLine);
      this.deadLineDate = deadline.toISOString().split('T')[0]; 
      this.deadLineTime = deadline.toTimeString().split(' ')[0].slice(0, 5); 
    }
  }

  onFormSubmit(event): void {
    const combinedDateTime = new Date(`${this.deadLineDate}T${this.deadLineTime}`);
    
    const updatedTask = {
      id: this.taskId,
      status: this.user.status || null,
      subject: this.user.subject || null,
      comments:this.user.comments || null,
      deadLine: combinedDateTime.getTime() 
    };

    this.mainService.taskbyid(this.userId, updatedTask).subscribe(
      (res: any) => {
        this.data = res;
        Swal.fire({
          title: 'Successfully updated',
          text: 'Your task has been updated successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogRef.close();
            this.router.navigate(['manager-dashboard/task']);
            window.location.reload();
          }
        });
      },
      (error:any) => {
        const errorMessage = error.error?.message || 'An unexpected error occurred. Please try again later.';
        Swal.fire('Error', errorMessage, 'error');
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
    window.location.reload();
  }
}
