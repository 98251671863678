<div class="full-page"><h4>Attendance</h4>
<div class="content-wrapper">
  <section class="Leads_manage leads_redesign">
    <button mat-mini-fab (click)="showFilterForm = !showFilterForm" style="margin-left:10px"><mat-icon>filter_alt</mat-icon>
    </button>
    <div class="filter-form-wrapper" *ngIf="showFilterForm">
      <form  #filterForm="ngForm"  (submit)="AttendanceFIlter($event)" style="display: flex" >
        <div class="form-field" style="margin-left: 21px">
          <label for="userIds">Select Employee:</label>
          <select id="userIds" name="employeeSelector" [(ngModel)]="user_id">
            <option *ngFor="let employee of employees" [value]="employee.id">
              {{ employee.userName }} 
            </option>
          </select>
        </div>
        <div class="form-field">
          <label for="start_date">Date From:</label>
          <input  name="start_date" [(ngModel)]="start_date" type="date" class="form-control" />
        </div>
        <div class="form-field">
          <label for="end_date">Date To:</label>
          <input name="end_date" [(ngModel)]="end_date" type="date" class="form-control" />
        </div>
        <button
          type="submit"
          style=" border: 0px solid white; margin-left: 21px;background: none; "  (click)="userLiveLocation(user_id)" >
          <img src="../../../../assets/current-location-icon.png" style="width: 34px; margin-left: -42px; margin-right: 12px;margin-top: 14px;" alt="Current Location"/>
        </button>
        <button mat-raised-button  color="accent" type="submit" style="background-color: #ff4081;
        height: 35px;
        margin-top: 15px;">Filter</button>
      </form>
    </div>
    <div class="container2" style="margin-top: 30px;">
      <div class="container-fluid scrollable-content">
        <div class="row">
          <div class="col-xs-12 col-lg-12" style="padding: 0px;">
            <div class="in_time_location">
              <div class="table-responsive table-fixed table-bg">
                <table class="table-bordered table-hover table-striped text-center" >
                  <thead>
                    <tr>
                      <th rowspan="2" style="width: 5%">Sn.</th>
                      <th
                        rowspan="2"
                        style="width: 5%"
                        *ngIf="!isClickedOnAttendance"
                      >
                        Name.
                      </th>
                      <th rowspan="2" style="width: 10%">Date</th>
                    </tr>
                    <tr>
                      <th style="width: 10%">In Time</th>
                      <th>Location</th>
                      <th style="width: 10%">Out Time</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  <tbody class="serialNumber">
                    <tr *ngFor="let user of userAttendanceList">
                      <td class="td_center">{{ user.serialNumber }}</td>

                      <td class="td_center" *ngIf="!isClickedOnAttendance">
                        {{ user.userName }}
                        <br /><br /><br />
                        <a
                          (click)="
                            liveLocation(
                              user.userId,
                              user.userName
                            )
                          "
                        >
                          <img
                            src="../../../../assets/loaction-icon.webp" alt="Location Icon"
                            style="width: 64px; margin-top: -26px; height: 40px"
                          />
                        </a>
                      </td>

                      <td class="td_center">{{ user.date }}</td>
                      <td class="td_center" *ngIf="user.inTime">
                        {{ user.inTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.inTime"></td>
                      <td style="height: 150px">
                        <agm-map
                          style="width: 100%; height: 100%"
                          [latitude]="user.inLat"
                          [longitude]="user.inLong"
                          [zoom]="15"
                          [mapTypeControl]="true"
                        >
                          <agm-marker
                            [latitude]="user.inLat"
                            [longitude]="user.inLong"
                          >
                          </agm-marker>
                        </agm-map>
                      </td>
                      <td class="td_center" *ngIf="user.outTime">
                        {{ user.outTime | date : "hh:mm a" }}
                      </td>
                      <td class="td_center" *ngIf="!user.outTime"></td>

                      <td style="height: 150px">
                        <agm-map
                          style="width: 100%; height: 100%"
                          [latitude]="user.outLat"
                          [longitude]="user.outLong"
                          [zoom]="15"
                          [mapTypeControl]="true"
                        >
                          <agm-marker
                            [latitude]="user.outLat"
                            [longitude]="user.outLong"
                          >
                          </agm-marker>
                        </agm-map>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
    </div>
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>
</div>
