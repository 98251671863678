import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-previous-task',
  templateUrl: './previous-task.component.html',
  styleUrls: ['./previous-task.component.css','/../../../shared/all-style/all-style.component.css'],
})
export class PreviousTaskComponent implements OnInit {
  id = localStorage.getItem('id');
  status: string = '';
  value: any;
  data: any[] = [];
  userId: string;
  showFilterForm: boolean = false;
  user_id: any;
  user: any;
  startDate: string = '';
  endDate: string = '';
  inputDateFromString: any;
  inputDateToString: any;
  pageSize: number = 10;
  pageNumber: number = 0;
  totalElements: number = 0;

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private route: ActivatedRoute,
    private auth: MainService
  ) {}

  ngOnInit(): void {
    this.fetchData(this.pageNumber, this.pageSize, this.status, this.startDate, this.endDate);
    this.auth.AllEmployeeProfileList().subscribe((res) => {
      this.user = res;
      JSON.stringify(this.user);
    });
  }

  fetchData(pageNumber: number, pageSize: number, status?: string, startDate?: string, endDate?: string, user_id?: string): void {
    const userId = user_id ;
    const formattedStartDate = startDate ? this.auth.toTimestamp(startDate) : '';
    const formattedEndDate = endDate ? this.auth.toTimestamp(endDate) : '';
    this.mainService.alltaskByStatus(userId, status, pageNumber, pageSize, formattedStartDate, formattedEndDate).subscribe((res: any) => {
      if (res && res.content) {
        this.data = res.content;
        this.totalElements = res.totalElements;
      }
    });
  }
  

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData(this.pageNumber, this.pageSize, this.status, this.startDate, this.endDate, this.user_id);
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }

  meetingFilter(): void {
    // console.log('Selected user_id:', this.user_id);
    // console.log('selected'+this.id);
    const userId = this.user_id ;
    this.inputDateFromString = this.auth.toTimestamp(this.startDate);
    this.inputDateToString = this.auth.toTimestamp(this.endDate);

    this.fetchData(this.pageNumber, this.pageSize, this.status, this.inputDateFromString, this.inputDateToString, userId);
  }

  clearFilters(): void {
    this.status = '';
    this.startDate = '';
    this.endDate = '';
    this.user_id = '';
    this.fetchData(this.pageNumber, this.pageSize);
  }

  getStatusColor(status: string): string {
    switch(status) {
      case 'PENDING':
        return 'rgb(103, 59, 141)';
      case 'DONE':
        return 'rgb(153, 102, 204)';
      case 'DUE':
        return 'rgb(77, 77, 179)';
      case 'REVIEWED':
        return 'rgb(103, 59, 141)';
      case 'ASSIGNED':
        return 'rgb(128, 128, 128)';
      default:
        return 'rgb(106, 106, 152)';
    }
  }

  openPopup(fileUrl: string): void {
    window.open(fileUrl, 'Document Viewer', 'width=600,height=400,resizable=yes,scrollbars=yes');
  }

  toggleFilterForm(){
    this.showFilterForm = !this.showFilterForm;
  }
}
