import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-user-leave-request',
  templateUrl: './user-leave-request.component.html',
  styleUrls: ['./user-leave-request.component.css','/../../../shared/all-style/all-style.component.css']
})
export class UserLeaveRequestComponent implements OnInit {
  userId: string;
  leaveDates: any[] = [];
  leavedata: any[] = [
    {
      leaveDates: [{ date: [] }],
    },
  ];

  content: any[];
  pageSize: number = 10;
  pageNumber: number = 0;
  startDate: string ='';
  endDate: string ='';
  showFilterForm: boolean;
  user_id: string;
  status: string;
  inputDateFromString: any;
  inputDateToString: any;
  totalElements:number =0; 
  employees:any;
  isFiltered:any;
  constructor(private http: HttpClient, private mainService: MainService, private router: Router) {}

  id = localStorage.getItem('id');
  data: any;
  
  showAdditionalDates: boolean[] = [];

  ngOnInit(): void {
    this.fetchData();
    // this.mainService.managerAlllist(this.id, true).subscribe((res: any) => {
    //   this.employees = res;
    // });
  }

  fetchData(userId: string = this.id, pageNumber: number = this.pageNumber, pageSize: number = this.pageSize, status = this.status, startDate: string = this.startDate, endDate: string = this.endDate) {
    if(this.isFiltered){
      this.onSubmitFilter();
    }else{
      
    this.inputDateFromString = this.mainService.toTimestamp(this.startDate);
    this.inputDateToString = this.mainService.toTimestamp(this.endDate);
    this.mainService.userleavebyid(userId, pageNumber, pageSize, this.inputDateFromString, this.inputDateToString,status).subscribe((res: any) => {
      
      if (res && res.content) {
        this.data = res.content;
        this.totalElements = res.totalElements; 
        JSON.stringify(this.data);
      } else {
        console.error("Response does not contain expected 'content' property:", res);
      }
    
    });
  }
  }
  

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData(this.user_id || this.id, this.pageNumber, this.pageSize, this.status, this.startDate, this.endDate);
  }

  onSubmitFilter() {
    const userId = this.user_id || this.id;
    this.inputDateFromString = this.mainService.toTimestamp(this.startDate);
    this.inputDateToString = this.mainService.toTimestamp(this.endDate);
    this.fetchData(userId, this.pageNumber, this.pageSize, this.status, this.inputDateFromString, this.inputDateToString);
  

  }

  getpendingtype(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'REJECTED':
        return 'Rejected';
      case 'APPROVED':
        return 'Approved';
      default:
        return status;
    }
  }

  navigateToAddUserTask() {
    this.router.navigate(['/user-dashboard/onuser-end-leave']);
  }

  getLeaveTypeText(leaveType: string): string {
    switch (leaveType) {
      case 'HALF_DAY':
        return 'Half Leave';
      case 'ABSENT':
        return 'Absent';
      case 'PAID_LEAVE':
        return 'Paid Leave';
      default:
        return leaveType;
    }
  }

  toggleAdditionalDates(id: string) {
    this.showAdditionalDates[id] = !this.showAdditionalDates[id];
  }

  toggleFilterForm() {
    this.showFilterForm = !this.showFilterForm;
  }

  clearFilters() {
    this.user_id = '';
    this.status = '';
    this.startDate = '';
    this.endDate = '';
    this.isFiltered = false;
    this.pageNumber = 0; 
    this.fetchData();
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
}
