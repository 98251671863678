<div class="full-page">
<h4> Meetings</h4>
  <div class="container2" >
    <div class="scrollable-content">
    <table>
      <thead>
        <tr>
          <th>UserIds</th>
          <th>Meeting Time</th>
          <th>Purpose</th>
          <th>Location</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of filteredData" class="data-row">
          <td>{{ data.id }}</td>
          <td>{{ data.meetingTime |date: 'dd-MMM-yyyy HH:mm a'  }}</td>
          <td>{{ data.purpose }}</td>
          <td>{{ data.location }}</td>
          <td>{{ data.description }}</td>
          <td>
            <ng-container *ngFor="let user of data.assignedUsers">
              <mat-slide-toggle [checked]="user.isUserPresent" *ngIf="user.assignToId == id.toString()" (change)="togglePresence(data,user)">
                {{ user.isUserPresent ? 'Present' : 'Absent' }}
              </mat-slide-toggle>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  </div>
  <div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Meetings
    </div>
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>