import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MainService } from 'src/app/MyService/main.service';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { NewComponent } from '../admin/new/new.component';
import {  UserAnnouncementComponent } from '../user/user-announcement/user-announcement.component';
import { AccouncementModuleComponent } from '../accouncement-module/accouncement-module.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginData = {
    userName: '',
    password: '',
  };
  forgotPasswordVisible = false;
  otpSent = false;
  email = '';
  otp: any;
  showForgetPasswordButton = false;
  fcmToken = localStorage.getItem('fcmToken');
  deviceToken: any;

  constructor(
    private snack: MatSnackBar,
    private login: LoginService,
    private router: Router,
    private mainService: MainService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  showPassword: boolean = false;

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  redirectToAdmin() {
    this.router.navigate(['/admin']);
  }

  toggleForgotPasswordForm(): void {
    this.forgotPasswordVisible = !this.forgotPasswordVisible;
    this.showForgetPasswordButton = false;
    this.otpSent = false;
  }

  deviceToken1 = {
    deviceToken: this.fcmToken
  };

  formSubmit() {
    console.log('login btn clicked');

    if (this.loginData.userName.trim() === '' || this.loginData.userName === null) {
      this.snack.open('Username is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    if (this.loginData.password.trim() === '' || this.loginData.password === null) {
      this.snack.open('Password is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    this.login.generateToken(this.loginData).subscribe(
      (data: any) => {
        console.log('success');
        this.login.loginUser(data.token);

        this.login.getCurrentUser().subscribe((user: any) => {
          this.login.setUser(user);
          console.log(user);
          localStorage.setItem('id', user['id']);
          localStorage.setItem('phone', user['phone']);
          localStorage.setItem('userName', user['userName']);
          localStorage.setItem('name', user['firstName']);
          localStorage.setItem('lastName', user['lastName']);
          let role = user.authorities[0].authority;
          localStorage.setItem('role', role);

          let timeoutId = setTimeout(() => {
            this.login.logout();
            this.router.navigate(['login']);
          }, 10 * 60 * 1000);

          document.addEventListener('mousemove', () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
              this.login.logout();
              this.router.navigate(['login']);
            }, 10 * 60 * 1000);
          });

          if (this.login.getUserRole() === 'ADMIN') {
            this.router.navigate(['admin']);
            this.login.loginStatusSubject.next(true);
            this.openManagerModal();
          } else if (this.login.getUserRole() === 'USER') {
            this.router.navigate(['user-dashboard']);
            this.login.loginStatusSubject.next(true);
            this.openManagerModal();
          }
          else if (this.login.getUserRole() === 'MANAGER') {
            this.router.navigate(['manager-dashboard']);
            this.login.loginStatusSubject.next(true);
            this.openManagerModal();
           } else {
            this.login.logout();
          }
        });
      },
      (error) => {
        console.log('Error !');
        console.log(error);
        this.snack.open('Invalid Details !! Try again', '', {
          duration: 3000,
        });
        this.showForgetPasswordButton = true;
      }
    );
  }

  sendOTP() {
    const user = {
      email: this.email,
    };

    const formData = new FormData();
    formData.append('email', this.email);

    this.mainService.sendOtpWithEmail(formData).subscribe(
      (res: any) => {
        Swal.fire({
          title: 'Success',
          text: 'OTP sent successfully',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            this.otpSent = true;
          }
        });
      },
      (error) => {
        console.error('Error sending OTP:', error);
        Swal.fire('Error', 'Failed to send OTP', 'error');
      }
    );
  }

  submitOTP() {
    console.log('OTP Value:', this.otp);

    this.mainService.verifyotp(this.otp, this.email).subscribe(
      (res: any) => {
        console.log('OTP Verification Response:', res);

        console.log('OTP Verification Failed');
        this.router.navigate(['/forget-password']);
        Swal.fire('success', 'Successfully OTP verified', 'success').then(() => {
          this.router.navigate(['/forget-password'], { queryParams: { email: this.email } });
        });
      },
      (error) => {
        console.error('Error verifying OTP:', error);
        Swal.fire('Error', 'Failed to verify OTP', 'error');
      }
    );
  }

//   openAdminModal() {
//     this.mainService.newevent().subscribe(
//       (data) => {
//         if (Object.keys(data).length > 0) {
//     this.dialog.open(NewComponent, {
//       width: '400px',
//       data: data
//     });
//   } else {
//     console.log('No data received from API.');
//   }
// },
// (error) => {
//   console.error('Error fetching data:', error);
// }
// );
//   }
  openManagerModal() {
    this.mainService.newevent().subscribe(
      (data) => {
        // Check if data exists and is not empty
        if (Object.keys(data).length > 0) {
    this.dialog.open(AccouncementModuleComponent, {
      width: '400px',
      data: data
    });
  } else {
    // Handle the case where data is empty
    console.log('No data received from API.');
  }
},
(error) => {
  // Handle API error
  console.error('Error fetching data:', error);
}
);
  }

  // openUserModal() {
  //   this.mainService.newevent().subscribe(
  //     (data) => {
  //       if (Object.keys(data).length > 0) {
  //         this.dialog.open(UserAnnouncementComponent, {
  //           width: '400px',
  //           data: data 
  //         });
  //       } else {
  //         console.log('No data received from API.');
  //       }
  //     },
  //     (error) => {
  //       console.error('Error fetching data:', error);
  //     }
  //   );
  // }
}
